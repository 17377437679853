import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { updateProfileSuccess, updateProfileFailure } from './actions';

import { signInSuccess } from '../auth/actions';

export function* updateProfile({ payload, profileid }) {
  try {
    const {
      name, cam_id, header, username, id, avatar_id, company, enterprise, ...rest
    } = payload.data;

    const profile = {
      name,
      username,
      avatar_id,
      company,
      enterprise,
      cam_id,
      header,
      user_id: id,
      ...(rest.oldPassword ? rest : {}),
    };

    // console.tron.log(payload.data)

    const response = yield call(api.put, 'users', profile);
    toast.success('Perfil atualizado com sucesso!');

    const response2 = yield call(api.post, 'sessionsupdate/', { username });
    const { token, user, tokenexpires } = response2.data;
    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(signInSuccess(token, user, tokenexpires));

    yield put(updateProfileSuccess(response.data));
  } catch (err) {
    if (err.response) { toast.error(err.response.data.error); } else { toast.error('Erro ao atualizar Perfil'); }
    yield put(updateProfileFailure());
  }
}

export function* updateToken({ payload, profileid }) {
  try {
    const response2 = yield call(api.post, 'sessionsupdate/');
    const { token, user, tokenexpires } = response2.data;
    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(signInSuccess(token, user, tokenexpires));
  } catch (err) {
    if (err.response) { toast.error(err.response.data.error); } else { toast.error('Erro ao atualizar Perfil'); }
    yield put(updateProfileFailure());
  }
}

export default all([
  takeLatest('@user/UPDATE_PROFILE_REQUEST', updateProfile),
  takeLatest('@user/UPDATE_TOKEN_REQUEST', updateToken),

]);
