import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input } from '@rocketseat/unform';
import * as Yup from 'yup';

import { signInRequest } from '~/store/modules/auth/actions';
import { productSet } from '~/store/modules/product/actions';

// import history from '~/services/history';
// import logo from '~/assets/Terence.png';
import { List27 } from '~/pages/_layouts/auth/styles';

const schema = Yup.object().shape({
  username: Yup.string()
    .required('O usuário é obrigatório'),
  password: Yup.string().required('A senha é obrigatória'),
});

export default function SignIn() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.auth.loading);

  function handleSubmit({ username, password }) {
    dispatch(productSet({
      order: '', page: 1, totalpages: 1, bar_code: '',
    }));
    dispatch(signInRequest(username, password));
  }
  function handleloginteste() {
    dispatch(productSet({
      order: '', page: 1, totalpages: 1, bar_code: '',
    }));
    dispatch(signInRequest('pedro@teste', 'senha'));
  }

  return (
    <>
      <Form schema={schema} onSubmit={handleSubmit}>
        <List27>
          <Input name="username" autoComplete="off" placeholder="Usuário" />
        </List27>
        <List27>
          <Input name="password" autoComplete="off" type="password" placeholder="Senha" />
        </List27>
        <List27>
          <button type="submit">
            { loading ? 'Carregando...' : 'Entrar'}
          </button>
        </List27>
      </Form>

      <Form>
        <List27>
          <button type="button" onClick={() => handleloginteste()}>
            Experimente!!!
          </button>
        </List27>
      </Form>

    </>
  );
}
