/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect, useMemo } from 'react';
import Chart from 'react-google-charts';
import { Form, Input } from '@rocketseat/unform';
import { toast } from 'react-toastify';

import { useSelector } from 'react-redux';

import { CSVLink } from 'react-csv';

import { format, parseISO, startOfMonth } from 'date-fns';
import pt from 'date-fns/locale/pt';
import history from '~/services/history';
import {
  List15, List16, List23, List24, Container,
} from '~/pages/_layouts/default/styles';
import api from '~/services/api';

function Charts() {
  const profile = useSelector((state) => state.user.profile);
  const [chartdata, setChartdata] = useState([]);

  const [date1, setDate1] = useState(startOfMonth(new Date()));
  const [date2, setDate2] = useState(new Date());
  const date1Formatted = useMemo(() => format(date1, 'YYY-MM-dd', { locale: pt }),
    [date1]);
  const date2Formatted = useMemo(() => format(date2, 'YYY-MM-dd', { locale: pt }),
    [date2]);

  const [button1, setButton1] = useState('1');
  const [button2, setButton2] = useState('1');
  const [button3, setButton3] = useState('1');

  const groupBy = (array, callBack) => new Set(array.map(callBack));
  const sumBy = (array, callBackFilter, callBackReduce) => array
    .filter(callBackFilter)
    .reduce(callBackReduce, 0);
  const createChart = (sortby, companys, data) => {
    const chartData = [];
    chartData.push(['Day', ...companys]);
    for (const item of sortby) {
      const chartItem = [];
      chartItem.push(item);
      for (const com of companys) {
        chartItem.push(
          sumBy(
            data,
            (a) => a.sorted === item && a.company === com,
            (a, b) => a + b.total_price,
          ),
        );
      }
      chartData.push(chartItem);
    }
    return chartData;
  };
  const createChart2 = (sortby, companys, data) => {
    const chartData = [];
    chartData.push(['Day', ...companys]);
    for (const item of sortby) {
      const chartItem = [];
      chartItem.push(item);
      // eslint-disable-next-line no-restricted-syntax
      for (const com of companys) {
        chartItem.push(
          sumBy(
            data,
            (a) => a.sorted === item && a.company === com,
            (a, b) => a + (b.total_price - b.average_cost * b.qty),
          ),
        );
      }
      chartData.push(chartItem);
    }
    return chartData;
  };

  async function loadchart1() {
    try {
      const response = await api.get('charts', { params: { date1, date2, company: '' } });
      const data = response.data.map((bb) => ({ ...bb, sorted: format(parseISO(bb.date), "d'/'MM", { locale: pt }) }));
      const sortby = groupBy(data, (item) => item.sorted);
      const companys = groupBy(data, (item) => item.company);
      const chartData = createChart(sortby, companys, data);
      setChartdata(chartData);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Erro ao atualizar Produto'); }
    }
  }
  async function loadchart2() {
    try {
      const response = await api.get('charts', { params: { date1, date2, company: '' } });
      const data = response.data.map((bb) => ({ ...bb, sorted: format(parseISO(bb.date), 'MMM/yy', { locale: pt }) }));
      const sortby = groupBy(data, (item) => item.sorted);
      const companys = groupBy(data, (item) => item.company);
      const chartData = createChart(sortby, companys, data);
      setChartdata(chartData);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Erro ao atualizar Produto'); }
    }
  }
  async function loadchart3() {
    try {
      const response = await api.get('charts', { params: { date1, date2, company: '' } });
      const data = response.data.map((bb) => ({ ...bb, sorted: bb.category }));
      const sortby = groupBy(data, (item) => item.sorted);
      const companys = groupBy(data, (item) => item.company);
      const chartData = createChart(sortby, companys, data);
      setChartdata(chartData);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Erro ao atualizar Produto'); }
    }
  }
  async function loadchart4() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({ ...bb, sorted: format(parseISO(bb.date), "d'/'MM", { locale: pt }) }));
    const sortby = groupBy(data, (item) => item.sorted);
    const companys = groupBy(data, (item) => item.company);
    const chartData = createChart2(sortby, companys, data);
    setChartdata(chartData);
  }
  async function loadchart5() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({ ...bb, sorted: format(parseISO(bb.date), 'MMM/yy', { locale: pt }) }));
    const sortby = groupBy(data, (item) => item.sorted);
    const companys = groupBy(data, (item) => item.company);
    const chartData = createChart2(sortby, companys, data);
    setChartdata(chartData);
  }
  async function loadchart6() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({ ...bb, sorted: bb.category }));

    const sortby = groupBy(data, (item) => item.sorted);
    const companys = groupBy(data, (item) => item.company);
    const chartData = createChart2(sortby, companys, data);
    setChartdata(chartData);
  }
  async function loadchart7() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.dia))];
    const chartData = uniqueCategories(data).map((dia) => [
      dia,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.dia === dia)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Dia', '$']);
    setChartdata(chartData);
    if (!chartdata.length) {
      setChartdata(chartData);
    }
  }
  async function loadchart8() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMM/yy', { locale: pt }),
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.mês))];
    const chartData = uniqueCategories(data).map((mês) => [
      mês,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.mês === mês)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Mês', '$']);
    setChartdata(chartData);
  }
  async function loadchart9() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const sum = (a, b) => a + b;
    const uniqueCategories = (array) => [...new Set(array.map((item) => item.category))];
    const chartData = uniqueCategories(response.data).map((category) => [
      category,
      response.data
        .filter((item) => item.active === true)
        .filter((item) => item.category === category)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Category', '$']);
    setChartdata(chartData);
  }
  async function loadchart10() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.dia))];
    const chartData = uniqueCategories(data).map((dia) => [
      dia,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.dia === dia)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Dia', '$']);
    setChartdata(chartData);
  }
  async function loadchart11() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMM/yy', { locale: pt }),
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.mês))];
    const chartData = uniqueCategories(data).map((mês) => [
      mês,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.mês === mês)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Mês', '$']);
    setChartdata(chartData);
  }
  async function loadchart12() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({
      ...bb,
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;
    const uniqueCategories = (array) => [...new Set(array.map((item) => item.category))];
    const chartData = uniqueCategories(data).map((category) => [
      category,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.category === category)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Categoria', '$']);
    setChartdata(chartData);
  }
  async function loadchart13() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
      hora: format(parseISO(bb.date), 'HH', { locale: pt }),
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.hora).sort())];
    const chartData = uniqueCategories(data).map((hora) => [
      hora,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.hora === hora)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Hora', '$']);
    setChartdata(chartData);
  }
  async function loadchart14() {
    try {
      const response = await api.get('charts', { params: { date1, date2, company: '' } });
      const data = response.data.map((bb) => ({ ...bb, sorted: format(parseISO(bb.date), 'HH', { locale: pt }) }));
      const sortby = groupBy(data, (item) => item.sorted);
      const companys = groupBy(data, (item) => item.company);
      const chartData = createChart(sortby, companys, data);
      setChartdata(chartData);
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Erro ao atualizar Produto'); }
    }
  }
  async function loadchart15() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
      hora: format(parseISO(bb.date), 'HH', { locale: pt }),
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.hora).sort())];
    const chartData = uniqueCategories(data).map((hora) => [
      hora,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.hora === hora)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Hora', '$']);
    setChartdata(chartData);
  }
  async function loadchart16() {
    const response = await api.get('charts', { params: { date1, date2, company: '' } });
    const data = response.data.map((bb) => ({ ...bb, sorted: format(parseISO(bb.date), 'HH', { locale: pt }) }));
    const sortby = groupBy(data, (item) => item.sorted);
    const companys = groupBy(data, (item) => item.company);
    const chartData = createChart2(sortby, companys, data);
    setChartdata(chartData);
  }

  async function loadchart7a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.dia))];
    const chartData = uniqueCategories(data).map((dia) => [
      dia,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.dia === dia)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Dia', '$']);
    setChartdata(chartData);
    if (!chartdata.length) {
      setChartdata(chartData);
    }
  }
  async function loadchart8a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMM/yy', { locale: pt }),
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.mês))];
    const chartData = uniqueCategories(data).map((mês) => [
      mês,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.mês === mês)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Mês', '$']);
    setChartdata(chartData);
  }
  async function loadchart9a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const sum = (a, b) => a + b;
    const uniqueCategories = (array) => [...new Set(array.map((item) => item.category))];
    const chartData = uniqueCategories(response.data).map((category) => [
      category,
      response.data
        .filter((item) => item.active === true)
        .filter((item) => item.category === category)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Category', '$']);
    setChartdata(chartData);
  }
  async function loadchart10a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.dia))];
    const chartData = uniqueCategories(data).map((dia) => [
      dia,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.dia === dia)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Dia', '$']);
    setChartdata(chartData);
  }
  async function loadchart11a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMM/yy', { locale: pt }),
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.mês))];
    const chartData = uniqueCategories(data).map((mês) => [
      mês,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.mês === mês)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Mês', '$']);
    setChartdata(chartData);
  }
  async function loadchart12a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const data = response.data.map((bb) => ({
      ...bb,
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;
    const uniqueCategories = (array) => [...new Set(array.map((item) => item.category))];
    const chartData = uniqueCategories(data).map((category) => [
      category,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.category === category)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Categoria', '$']);
    setChartdata(chartData);
  }
  async function loadchart13a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
      hora: format(parseISO(bb.date), 'HH', { locale: pt }),
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.hora).sort())];
    const chartData = uniqueCategories(data).map((hora) => [
      hora,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.hora === hora)
        .map((item) => item.total_price)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Hora', '$']);
    setChartdata(chartData);
  }
  async function loadchart15a() {
    const response = await api.get('charts', { params: { date1, date2 } });
    const data = response.data.map((bb) => ({
      ...bb,
      dia: format(parseISO(bb.date), "d'/'MM", { locale: pt }),
      mês: format(parseISO(bb.date), 'MMMM', { locale: pt }),
      hora: format(parseISO(bb.date), 'HH', { locale: pt }),
      lucro: bb.total_price - bb.average_cost * bb.qty,
    }));
    const sum = (a, b) => a + b;

    const uniqueCategories = (array) => [...new Set(array.map((item) => item.hora).sort())];
    const chartData = uniqueCategories(data).map((hora) => [
      hora,
      data
        .filter((item) => item.active === true)
        .filter((item) => item.hora === hora)
        .map((item) => item.lucro)
        .reduce(sum),
    ]);
    chartData.splice(0, 0, ['Hora', '$']);
    setChartdata(chartData);
  }

  function handlesetChart(data1, data2, data3) {
    if (data1 === '1') {
      if (data2 === '1') {
        if (data3 === '1') { loadchart7(); }
        if (data3 === '2') { loadchart1(); }
        if (data3 === '3') { loadchart7a(); }
      }
      if (data2 === '2') {
        if (data3 === '1') { loadchart8(); }
        if (data3 === '2') { loadchart2(); }
        if (data3 === '3') { loadchart8a(); }
      }
      if (data2 === '3') {
        if (data3 === '1') { loadchart9(); }
        if (data3 === '2') { loadchart3(); }
        if (data3 === '3') { loadchart9a(); }
      }
      if (data2 === '4') {
        if (data3 === '1') { loadchart13(); }
        if (data3 === '2') { loadchart14(); }
        if (data3 === '3') { loadchart13a(); }
      }
    }
    if (data1 === '2') {
      if (data2 === '1') {
        if (data3 === '1') { loadchart10(); }
        if (data3 === '2') { loadchart4(); }
        if (data3 === '3') { loadchart10a(); }
      }
      if (data2 === '2') {
        if (data3 === '1') { loadchart11(); }
        if (data3 === '2') { loadchart5(); }
        if (data3 === '3') { loadchart11a(); }
      }
      if (data2 === '3') {
        if (data3 === '1') { loadchart12(); }
        if (data3 === '2') { loadchart6(); }
        if (data3 === '3') { loadchart12a(); }
      }
      if (data2 === '4') {
        if (data3 === '1') { loadchart15(); }
        if (data3 === '2') { loadchart16(); }
        if (data3 === '3') { loadchart15a(); }
      }
    }
  }

  useEffect(() => {
    handlesetChart(button1, button2, button3);
    // eslint-disable-next-line
  }, [profile.company, chartdata.length, date1, date2]);

  function handlesetButton1(data) {
    setButton1(data);
    handlesetChart(data, button2, button3);
  }
  function handlesetButton2(data) {
    setButton2(data);
    handlesetChart(button1, data, button3);
  }
  function handlesetButton3(data) {
    setButton3(data);
    handlesetChart(button1, button2, data);
  }

  function handlesetDate1(data) {
    setDate1(parseISO(data));
    handlesetChart(button1, button2, button3);
  }
  function handlesetDate2(data) {
    setDate2(parseISO(data));
    handlesetChart(button1, button2, button3);
  }

  return (
    <Container>
      <Form>
        <strong>Período</strong>
        <List24>
          <Input name="date1" value={date1Formatted} onChange={(e) => handlesetDate1(e.target.value)} type="date" placeholder="Data Inicio" />
          <Input name="date2" value={date2Formatted} onChange={(e) => handlesetDate2(e.target.value)} type="date" placeholder="Data Fim" />
        </List24>
        <strong>Vertical</strong>
        <List24>
          <List16 noactive={button1 !== '1'} onClick={() => handlesetButton1('1')}>Faturamento</List16>
          <List16 noactive={button1 !== '2'} onClick={() => handlesetButton1('2')}>Lucro</List16>
        </List24>
        <strong>Empresa</strong>
        <List15>
          <List16 noactive={button3 !== '1'} onClick={() => handlesetButton3('1')}>Consolidado</List16>
          <List16 noactive={button3 !== '2'} onClick={() => handlesetButton3('2')}>Separado</List16>
          <List16 noactive={button3 !== '3'} onClick={() => handlesetButton3('3')}>{profile.company}</List16>
        </List15>
        <strong>Horizontal</strong>
        <List23>
          <List16 noactive={button2 !== '1'} onClick={() => handlesetButton2('1')}>Dias</List16>
          <List16 noactive={button2 !== '2'} onClick={() => handlesetButton2('2')}>Meses</List16>
          <List16 noactive={button2 !== '3'} onClick={() => handlesetButton2('3')}>Categoria</List16>
          <List16 noactive={button2 !== '4'} onClick={() => handlesetButton2('4')}>Hora</List16>
        </List23>
      </Form>
      <hr />

      <Chart
        height={300}
        chartType="ColumnChart"
        loader={<div>Loading Chart</div>}
        data={chartdata}
        options={{
          animation: { startup: true, easing: 'linear', duration: 1000 },
          title: '',
          chartArea: { width: '70%' },
          // hAxis: { title: 'Dia', minValue: 0 },
          vAxis: { minValue: 0 },
        }}
        legendToggle
      />

      <CSVLink data={chartdata}>Download me</CSVLink>
    </Container>
  );
}

export default Charts;
