import { combineReducers } from 'redux';

import auth from './auth/reducer';
import user from './user/reducer';
import product from './product/reducer';
import sale from './sale/reducer';
import payment from './payment/reducer';
import basket from './basket/reducer';

export default combineReducers({
  auth,
  user,
  product,
  sale,
  payment,
  basket,
});
