import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from '@unform/web';
import { Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import history from '~/services/history';
import Input from '~/components/Input';
import { updateProfileRequest } from '~/store/modules/user/actions';
// import AvatarInput from './AvatarInput';
// import { Container } from './styles';
import api from '~/services/api';
import { Container, List17 } from '~/pages/_layouts/default/styles';
import { signOut } from '~/store/modules/auth/actions';

export default function App() {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.user.profile);
  const [companyvalue, setCompanyvalue] = useState(profile.company);
  const [camvalue, setCamvalue] = useState(profile.cam_id);
  const [headervalue, setHeadervalue] = useState(profile.header);
  const optionsheader = [
    { id: 'below', title: 'Abaixo' },
    { id: 'top', title: 'Acima' },
  ];

  function handleSubmit(e) {
    let data = {};
    data = e;
    data.id = profile.id;
    data.username = profile.username;
    data.company = companyvalue;
    data.cam_id = camvalue;
    data.header = headervalue;
    dispatch(updateProfileRequest(data));
  }

  function handleSignOut() {
    dispatch(signOut());
  }

  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function getcompany() {
      try {
        const response = await api.get('enterprise');

        const data = response.data.map((bb) => ({
          id: bb.company,
          title: bb.company,
        }));
        setOptions(data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar perfil'); }
      }
    }
    getcompany();
  }, [profile]);

  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.setData({
      name: profile.name,
    });
  }, [profile.name]);

  const [optionscam, setOptionscam] = useState([]);
  useEffect(() => {
    async function gotDevices() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      const opções = [];
      mediaDevices.forEach((mediaDevice, _id) => {
        if (mediaDevice.kind === 'videoinput') {
          opções.push({ id: _id, title: mediaDevice.label || `Camera ${_id}` });
        }
      });
      setOptionscam(opções);
    }
    gotDevices();
  }, []);

  return (
    <Container>

      <Form ref={formRef} onSubmit={handleSubmit}>
        {/* <AvatarInput name="avatar_id" /> */}

        <Input name="name" label="Nome" placeholder="Nome Completo" />
        {/* <strong name="username" placeholder="Usuário" /> */}

        { profile.level ? <></>
          : (
            <Select
              name="company"
              label="Empresa (clique no logotipo para trocar)"
              placeholder="Empresa"
              onChange={(e) => setCompanyvalue(e.target.value)}
              value={companyvalue}
              options={options}
            />
          )}

        <span>Selecionar Câmera</span>
        <Select options={optionscam} value={camvalue} onChange={(e) => setCamvalue(e.target.value)} placeholder="Camera" name="cam_id" />
        <span>Posição do Menu</span>
        <Select options={optionsheader} value={headervalue} onChange={(e) => setHeadervalue(e.target.value)} placeholder="Posição do Menu" name="header" />

        <strong>Alterar Senha</strong>

        <List17>
          <Input type="password" name="oldPassword" placeholder="Senha atual" />
          <Input type="password" name="password" placeholder="Nova senha" />
          <Input type="password" name="confirmPassword" placeholder="Confirmação" />
        </List17>

        <button type="submit">Atualizar perfil</button>
        <Input name="id" type="hidden" placeholder="seu id" />
      </Form>
      <Form>
        <hr />
      </Form>
      <button type="button" onClick={handleSignOut}>Sair</button>

    </Container>
  );
}
