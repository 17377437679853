import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@unform/web';
import { toast } from 'react-toastify';
import history from '~/services/history';
import Input from '~/components/Input';
import api from '~/services/api';
import { Container } from '~/pages/_layouts/default/styles';

import { signInRequest } from '~/store/modules/auth/actions';
import { productSet } from '~/store/modules/product/actions';

export default function NewEnterprise() {
  const dispatch = useDispatch();

  async function handleSubmit(data) {
    try {
      await api.post('enterprise', {
        ...data,
        company: data.enterprise,
        avatar_id: 23,
        plan: 30,
        balance: 90,
        last_debt: new Date(),
      });

      await dispatch(productSet({
        order: '', page: 1, totalpages: 1, bar_code: '',
      }));
      await dispatch(signInRequest(data.username, data.password));

      toast.success('Empresa cadastrada com sucesso!');
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao cadastrar empresa'); }
    }
  }

  const [username, setUsername] = useState('admin@');

  return (
    <Container>

      <Form onSubmit={handleSubmit}>
        <Input name="name" placeholder="Seu Nome" />
        <Input name="enterprise" onChange={(e) => setUsername(`admin@${e.target.value.toLowerCase()}`)} placeholder="Nome da Empresa" />
        <Input type="email" name="email" placeholder="Seu e-mail" />

        <hr />
        <strong>Não esqueça Seu Usuário:</strong>
        <Input type="usuário" disabled name="username" value={username} placeholder="usuário" />
        <Input type="password" name="password" placeholder="Sua senha" />
        <button type="submit">Criar Empresa</button>
      </Form>

    </Container>
  );
}
