import React from 'react';
import { useDispatch } from 'react-redux';

import { signOut } from '~/store/modules/auth/actions';

export default function SignOut() {
  const dispatch = useDispatch();
  dispatch(signOut());
  return (
    <>

    </>
  );
}
