import React from 'react';
import { Container, Container6 } from '~/pages/_layouts/default/styles';

import pix from '~/assets/pix.jpg';

export default function Deposit() {
  return (
    <Container>
      <Container6>
        <strong>
          Faça um PIX para a conta abaixo:
        </strong>
        <img alt="PIX" width="300px" src={pix} />
      </Container6>

    </Container>
  );
}
