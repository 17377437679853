import React, { useRef, useEffect, useState } from 'react';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { Select } from '@rocketseat/unform';
import api from '~/services/api';
import { Container6, List10 } from '~/pages/_layouts/default/styles';
import history from '~/services/history';
import Input from '~/components/Input';

const schema = Yup.object().shape({
  name: Yup.string().required('O nome é obrigatório'),
  username: Yup.string()
    .required('O usuário é obrigatório'),
  password: Yup.string().min(6, 'No mínimo 6 caracteres').required('A senha é obrigatória'),
});

export default function SignUp() {
  const profile = useSelector((state) => state.user.profile);
  const [companyvalue, setCompanyvalue] = useState(profile.company);

  async function handleSubmit(data) {
    try {
      await api.post('users', {
        ...data,
        company: companyvalue,
        enterprise: profile.enterprise,
        username: `${data.username}@${profile.enterprise}`,
      });
      toast.success('Usuário cadastrado com sucesso');
    } catch (err) {
      if (err.response) { toast.error(err.response.data.error); } else { toast.error('Falha ao cadastrar'); }
    }
  }

  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function getcompany() {
      try {
        const response = await api.get('enterprise');

        const data = response.data.map((bb) => ({
          id: bb.company,
          title: bb.company,
        }));
        setOptions(data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar perfil'); }
      }
    }
    getcompany();
  }, [profile]);

  const formRef = useRef(null);
  useEffect(() => {
    formRef.current.setData({
      user: '',
    });
  }, [profile]);

  const [user, setUser] = useState('');
  return (
    <Container6>
      <Form ref={formRef} schema={schema} onSubmit={handleSubmit}>
        <Input autoComplete="off" name="name" placeholder="Nome" />
        <Select name="company" placeholder="Empresa" onChange={(e) => setCompanyvalue(e.target.value)} value={companyvalue} options={options} />
        <List10>
          <Input name="username" value={user} onChange={(e) => setUser(e.target.value)} placeholder="Usuário" />
          <Input autoComplete="off" name="enterprise" disabled value={`@${profile.enterprise}`} placeholder="@Empresa" />
        </List10>
        <Input autoComplete="off" name="password" type="password" placeholder="Senha" />
        <button type="submit"> Criar Vendedor</button>
      </Form>
    </Container6>
  );
}
