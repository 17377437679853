import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { toast } from 'react-toastify';
import { formatPrice } from '~/util/format';
import history from '~/services/history';

import api from '~/services/api';
import {
  Container, List14, List20,
} from '~/pages/_layouts/default/styles';

function Products() {
  const [schedule, setSchedule] = useState([]);

  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    async function loadStock() {
      try {
        const response = await api.get('stock');
        const data = response.data.map((bb) => ({
          ...bb,
          total: bb.stock * bb.average_cost,
        }));
        const uniqueCategories = (array) => [...new Set(array.map((item) => item.category))];
        const copy = [];
        uniqueCategories(data).forEach((element, id) => {
          copy.push({
            id,
            category: element,
            total: data
              .filter((item) => item.category === element)
              .map((item) => item.total)
              .reduce((a, b) => a + b),
          });
        });
        setSchedule(copy.sort((a, b) => (a.total > b.total ? -1 : 1)));
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }
    loadStock();
  }, [profile.company]);

  return (
    <Container>
      <List20>

        { schedule.map((aa) => (
          <List14 key={String(aa.id)}>
            <span>
              {aa.category}
              {' '}
            </span>
            <aside>
              <span>{formatPrice(aa.total)}</span>
            </aside>

          </List14>
        ))}
      </List20>
    </Container>
  );
}

export default Products;
