import React, { useState, useEffect, useRef } from 'react';
import { FaBarcode } from 'react-icons/fa';
import { useSelector, useDispatch } from 'react-redux';
import {
  Form, Input, Select, Check,
} from '@rocketseat/unform';
import * as Yup from 'yup';
import { MdAddCircleOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import { VscLoading } from 'react-icons/vsc';
import history from '~/services/history';
import api from '~/services/api';
// import { Container } from './styles';
// import { object } from "prop-types";
import Scanner from '~/components/BarCode/Scanner';
import Result from '~/components/BarCode/Result';
import {
  Container6, List13, List10, List11,
} from '~/pages/_layouts/default/styles';

import { productSet } from '~/store/modules/product/actions';

const schema = Yup.object().shape({
  bar_code: Yup.string().required('O código é obrigatório'),
  name: Yup.string().required('O Nome é obrigatório'),
  category: Yup.string().required('A categoria é obrigatório'),
  stock: Yup.number(),
  stockpile: Yup.boolean(),
  price: Yup.number()
    .required('O preço é obrigatório')
    .transform((_, value) => +value.replace(/,/, '.'))
    .positive('O preço deve ser positivo'),
  average_cost: Yup.number()
    .transform((_, value) => +value.replace(/,/, '.')),
});

export default function Profile() {
  const profile = useSelector((state) => state.user.profile);
  const product = useSelector((state) => state.product.product);
  const [options, setOptions] = useState([]);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function loadcategories() {
      try {
        const response = await api.get('category');
        const data = response.data.map((bb) => ({
          id: bb.id,
          title: bb.category,
        }));
        setOptions(data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar categorias'); }
      }
    }
    loadcategories();
  }, [profile.company]);

  async function handleSubmit(data) {
    if (!loading) {
      setLoading(true);
      dispatch(productSet({
        order: product.order, page: 1, totalpages: 1, bar_code2: '',
      }));
      try {
        await api.post('products', {
          ...data,
          description: 'Descrição',
          company: profile.company,
          enterprise: profile.enterprise,
        });
        toast.success('Produto cadastrado com sucesso!');
        history.push('/products');
      } catch (err) {
        setLoading(false);
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao cadastrar o produto'); }
      }
    }
  }

  const [bar_code, setBar_code] = useState();
  const [BarCodeScanning, setBarCodeScanning] = useState(false);
  const [results, setResults] = useState([]);
  const scannerRef = useRef(null);

  useEffect(() => {
    async function loadProduct() {
      if (results[0]) {
        setBar_code(results[0]);
        setBarCodeScanning(false);
        setShowBarcodeScanner(false);

        setResults([]);
      }
    }
    loadProduct();
  }, [results]);

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner);
    setBarCodeScanning(!BarCodeScanning);
  }

  const [getcamid, setGetcamid] = useState();
  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile.cam_id) {
          setGetcamid(mediaDevice.deviceId);
        }
      });
    }
    loadCam_id();
  }, [profile.cam_id]);

  return (
    <Container6>
      <Form schema={schema} onSubmit={handleSubmit}>
        <>

          {/*

      <Container2>
        <label htmlFor="photo2" name="aa">
          <img src={
            preview || 'https://api.adorable.io/avatars/50/abott@adorable.png'
            }
            alt="" />

          <input
            type="file"
            id="photo2"
            accept="image/*"
            data-file={file}
            onChange={handleChange}
            ref={ref}
            />
        </label>
      </Container2>
    */}
        </>

        <List13>
          <Input name="bar_code" value={bar_code} onChange={(e) => setBar_code(e.target.value)} placeholder="Código" />
          <FaBarcode size={30} onClick={() => handleBarcode()} color="#FFF" />
        </List13>

        { !showBarcodeScanner ? <></>
          : (
            <div>
              <ul className="results">
                {results.map((result) => (result.codeResult
                && <Result key={result.codeResult.code} result={result} />))}
              </ul>
              <div ref={scannerRef} style={{ position: 'relative' }}>
                <canvas className="drawingBuffer" style={{ position: 'absolute', top: '0px', border: '3px solid green' }} width="480" height="350" />
                { BarCodeScanning
                  ? (
                    <Scanner
                      aaaa={getcamid}
                      scannerRef={scannerRef}
                      teste={results}
                      onDetected={(result) => setResults([...results, result])}
                    />
                  ) : null}
              </div>
            </div>
          )}

        <Input name="name" placeholder="Nome do Produto" />

        <List13>
          <Select name="category" placeholder="Categoria do Produto" options={options} />
          { profile.level ? null
            : <MdAddCircleOutline size={30} color="#fff" onClick={() => history.push('/newcategory')} />}

        </List13>

        <List10>
          <Input name="price" placeholder="Preço do Produto" />
          { profile.level ? <></> : <Input name="average_cost" placeholder="Custo do Produto" /> }
        </List10>
        <span>Estoque</span>
        <List11>
          <ul>
            { profile.level ? <></> : <Check name="stockpile" /> }
          </ul>
          <Input name="stock" placeholder="Quantidade" />
        </List11>

        { loading ? <VscLoading /> : <button type="submit">Adicionar Produto</button>}
      </Form>
    </Container6>
  );
}
