import produce from 'immer';

const INITIAL_STATE = {
  basket: null,
};

export default function basket(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@basket/BASKET_SET': {
        draft.basket = action.payload.basket;
        break;
      }
      case '@basket/BASKET_SET_SUCCESS': {
        draft.basket = action.payload.basket;
        break;
      }
      default:
    }
  });
}
