/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
  format, addMonths, subMonths, startOfMonth, endOfMonth, parseISO, isAfter,
} from 'date-fns';
import { useDispatch, useSelector } from 'react-redux';
import pt from 'date-fns/locale/pt';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import * as Yup from 'yup';
import { formatPrice } from '~/util/format';

import history from '~/services/history';

import api from '~/services/api';
import {
  Container, List3, List21, List10, List20,
} from '~/pages/_layouts/default/styles';

import { paymentSet } from '~/store/modules/payment/actions';

const schema = Yup.object().shape({
  price: Yup.number()
    .required('O valor é obrigatório')
    .transform((_, value) => +value.replace(/,/, '.'))
    .positive('O valor deve ser positivo'),
  category: Yup.string().required('A categoria é obrigatório'),
  description: Yup.string().required('A descrição é obrigatória'),

});

export default function Payments() {
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState([]);
  const [date, setDate] = useState(new Date());
  const profile = useSelector((state) => state.user.profile);
  const [loading, Setloading] = useState(false);
  const [month, setMonth] = useState(new Date());
  const [selectedcategory, setSelectedcategory] = useState();
  const options = [
    { id: 'Fornecedor', title: 'Fornecedor' },
    { id: 'Funcionário', title: 'Funcionário' },
    { id: 'Diversos', title: 'Diversos' },
  ];

  const [showright, setShowright] = useState(false);
  useEffect(() => {
    async function loadPayments() {
      try {
        const date1 = startOfMonth(month);
        const date2 = endOfMonth(month);
        const response = await api.get('payments', { params: { date1, date2 } });
        setSchedule(response.data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar pagamentos'); }
      }
    }
    loadPayments();
  }, [date, month, profile.company]);

  function handlePrevMonth() {
    setMonth(subMonths(month, 1));
    if (isAfter(new Date(), subMonths(month, 2))) {
      setShowright(true);
    } else { setShowright(false); }
  }
  function handleNextMonth() {
    setMonth(addMonths(month, 1));
    if (isAfter(new Date(), addMonths(month, 2))) {
      setShowright(true);
    } else { setShowright(false); }
  }

  async function handleSubmit(e) {
    Setloading(true);
    try {
      await api.post('payments', {
        category: selectedcategory,
        company: profile.company,
        enterprise: profile.enterprise,
        price: e.price,
        description: e.description,
        seller: profile.username,
        date: new Date(),
      });

      toast.success('Pagamento realizado com sucesso!');
      setDate(new Date());
    } catch (err) {
      if (err.response) { toast.error(err.response.data.error); } else { toast.error('Falha ao Pagar'); }
    }
    Setloading(false);
  }

  function handleEdit(data) {
    setSchedule([]);
    dispatch(paymentSet({
      ...data,
    }));
  }

  return (

    <Container>
      <>
        <List21>
          <button type="button" onClick={handlePrevMonth}>
            <MdChevronLeft size={30} color="#ffff" />
          </button>
          <strong>{format(month, 'MMMM/YYY', { locale: pt })}</strong>
          { !showright ? null
            : (
              <button type="button" onClick={handleNextMonth}>
                <MdChevronRight size={30} color="#ffff" />
              </button>
            )}
        </List21>
      </>

      <Form schema={schema} onSubmit={handleSubmit}>
        <List10>
          <Input name="price" placeholder="Valor" />
          <Select name="category" placeholder="Categoria" onChange={(e) => setSelectedcategory(e.target.value)} options={options} />
        </List10>
        <Input name="description" placeholder="Descrição" />

        { !loading
          ? <button type="submit"> Efeturar Pagamento</button>
          : <button type="button">Carregando...</button>}
      </Form>

      <List20>
        { schedule.map((aa, _id) => (
          <List3
            onClick={() => handleEdit(schedule[_id])}
            key={String(aa.id)}
            noactive={!aa.active}
          >

            <span>
              {format(parseISO(aa.created_at), "dd'/'MM'-'HH:mm", { locale: pt })}
              {' '}
              {aa.description}
            </span>

            <aside3>
              <strong>{formatPrice(aa.price)}</strong>
            </aside3>

          </List3>
        ))}

      </List20>

    </Container>
  );
}
