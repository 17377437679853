import React, { useState, useEffect } from 'react';
// import AvatarInput from './AvatarInput';
// import { Container } from './styles';
import { toast } from 'react-toastify';
import api from '~/services/api';
import { Container6, List20, List7 } from '~/pages/_layouts/default/styles';
import history from '~/services/history';

export default function Config() {
  const [schedule, setSchedule] = useState([]);

  useEffect(() => {
    async function loadusers() {
      try {
        const users = await api.get('users');
        setSchedule(users.data.sort((a, b) => (a.company > b.company ? -1 : 1)));
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }
    loadusers();
  }, []);

  return (
    <Container6>

      <List20>
        { schedule.map((aa) => (
          <List7 key={String(aa.id)}>
            <strong>{aa.username}</strong>
            <strong>{aa.company}</strong>
            <strong>{aa.level}</strong>
          </List7>
        ))}
      </List20>

    </Container6>
  );
}
