import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import {
  Form, Input, Check, Select,
} from '@rocketseat/unform';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '~/services/api';
import history from '~/services/history';
import {
  Container6, List10, List11, List17,
} from '~/pages/_layouts/default/styles';

function EditSale() {
  const sale = useSelector((state) => state.sale.sale);
  const profile = useSelector((state) => state.user.profile);
  const [options, setOptions] = useState([]);
  const optionspgto = [
    { id: 'Dinheiro', title: 'Dinheiro' },
    { id: 'Débito', title: 'Débito' },
    { id: 'Crédito', title: 'Crédito' },
    { id: 'Pix', title: 'Pix' },
  ];
  const [newdate, setNewdate] = useState(sale.date);
  const dateFormatted = useMemo(() => format(parseISO(newdate), "YYY-MM-dd'T'HH:mm", { locale: pt }),
    [newdate]);
  const [categoryvalue, setCategoryvalue] = useState(sale.category);

  useEffect(() => {
    async function loadcategories() {
      const response = await api.get('category');
      const data = response.data.map((bb) => ({
        id: bb.id,
        title: bb.category,
      }));
      setOptions(data);
    }
    loadcategories();
  }, [profile.company]);

  // eslint-disable-next-line consistent-return
  async function handleSubmit(data) {
    try {
      // data.total_price = data.qty * data.price
      if (!sale.active && data.active) {
        return toast.error('Não é posível reativar uma venda');
      }

      const cash = await api.get('lastcash');
      const last_cash = format(parseISO(cash.data[0].date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt });
      const data_venda = format(parseISO(sale.date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt });
      if (last_cash > data_venda) {
        return toast.error('Caixa fechado');
      }
      if (last_cash > newdate) {
        return toast.error('Caixa fechado');
      }

      data.date = format(parseISO(newdate), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt });

      if (sale.pgto2) {
        data.pgto = sale.pgto;
        data.pgto2 = sale.pgto2;
        data.pgtoValue = sale.pgtoValue;
        data.pgto2Value = sale.pgto2Value;
      }

      if (!sale.pgto2 && sale.pgtoValue !== (data.price * data.qty - (data.discount || 0))) {
        data.pgtoValue = data.price * data.qty - (data.discount || 0);
      }

      await api.put('sales', {
        data,
        id: sale.id,
      });

      if (sale.active !== data.active) {
        const { bar_code } = sale;
        const response = await api.get('products3', { params: { bar_code } });

        const newqty = sale.active ? -sale.qty : sale.qty;
        if (response.data.products[0]) {
          await api.put('products4', {
            id: response.data.products[0].id,
            subtract_stock: newqty,
          });
        }
      }

      if (sale.qty !== data.qty && data.active) {
        const { bar_code } = sale;
        const response = await api.get('products3', { params: { bar_code } });

        if (response.data.products[0]) {
          await api.put('products4', {
            id: response.data.products[0].id,
            subtract_stock: data.qty - sale.qty,
          });
        }
      }

      toast.success('Venda atualizada com sucesso');
      history.push('/sales');
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao atualizar a venda'); }
    }
  }

  return (
    <Container6>
      <Form initialData={sale} onSubmit={handleSubmit}>
        <Link to="/sales"><MdChevronLeft size={30} /></Link>

        <List10>
          <span>Quantidade</span>
          <span>Usuário</span>
        </List10>
        <List10>
          <Input name="qty" placeholder="Quantidade" disabled={!!sale?.pgto2} />
          <Input name="seller" disabled placeholder="Usuário" />
        </List10>

        <span>Produto</span>
        <Input name="name" placeholder="Nome do Produto" />

        <List10>
          <span>Categoria</span>
          <span>Forma de Pagamento</span>
        </List10>
        <List10>
          <Select name="category" placeholder="Categoria do Produto" onChange={(e) => setCategoryvalue(e.target.value)} value={categoryvalue} options={options} />
          {sale?.pgto2
            ? <Input name="pgto2" disabled value={`${sale.pgto} / ${sale.pgto2}`} />
            : <Select name="pgto" placeholder="Forma de Pagamento" options={optionspgto} />}
        </List10>

        <List17>
          <span>Preço</span>
          <span>Desconto</span>
          { profile.level ? null : <span>Custo</span>}
        </List17>
        <List17>
          <Input name="price" placeholder="Preço" width="20px" disabled />
          <Input name="discount" type="number" step="0.01" disabled={!!sale?.pgto2} placeholder="Desconto" width="20px" />
          { profile.level ? <></> : <Input name="average_cost" placeholder="Custo" /> }
        </List17>

        <span>Valor Total</span>
        <Input name="total_price" disabled placeholder="Valor total da Venda" />

        <List10>
          <span>Observação</span>
          <span>Estoque Atual</span>
        </List10>
        <List10>
          <Input name="note" placeholder="Observação" />
          <Input name="atual_stock" placeholder="Estoque Atual" disabled />
        </List10>

        <span>Data</span>
        <Input name="date" value={dateFormatted} onChange={(e) => setNewdate(e.target.value)} type="datetime-local" placeholder="Data da Venda" />

        <span>Ativo</span>
        <List11>
          <ul>
            <Check width="5px" name="active" />
          </ul>
          <button type="submit">Atualizar Venda</button>
        </List11>
      </Form>
    </Container6>
  );
}

export default EditSale;
