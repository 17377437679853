import { takeLatest, all, put } from 'redux-saga/effects';
import history from '~/services/history';

import { paymentSetSuccess } from './actions';

export function* loadPayment({ payload }) {
  yield put(paymentSetSuccess(payload.data));
  history.push('/editpayment');
}

export default all([
  takeLatest('@payment/PAYMENT_SET', loadPayment),
]);
