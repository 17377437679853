import React from 'react';
import AvatarInput from './AvatarInput';
import { Container } from '~/pages/_layouts/default/styles';

export default function Upload() {
  return (
    <Container>
      <AvatarInput name="avatar_id" />
    </Container>
  );
}
