export function productSet(data) {
  return {
    type: '@product/PRODUCT_SET',
    payload: { data },
  };
}
export function productSetSuccess(product) {
  return {
    type: '@product/PRODUCT_SET_SUCCESS',
    payload: { product },
  };
}
