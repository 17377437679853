import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import {
  Form, Input, Check, Select,
} from '@rocketseat/unform';
import { RiAddCircleLine } from 'react-icons/ri';
import * as Yup from 'yup';
import history from '~/services/history';
import { Container6, List10, List11 } from '~/pages/_layouts/default/styles';

import api from '~/services/api';
// import { Container, Span2 } from './styles';

const schema = Yup.object().shape({
  bar_code: Yup.string().required('O código é obrigatório'),
  name: Yup.string().required('O Nome é obrigatório'),
  category: Yup.string().required('A categoria é obrigatório'),
  stock: Yup.number(),
  addstock: Yup.number(),
  addstockprice: Yup.number(),
  stockpile: Yup.boolean(),
  active: Yup.boolean(),

  price: Yup.number()
    .required('O preço é obrigatório')
    .transform((_, value) => +value.replace(/,/, '.'))
    .positive('O preço deve ser positivo'),
  average_cost: Yup.number()
    .transform((_, value) => +value.replace(/,/, '.')),
});

function EditProduct() {
  const product = useSelector((state) => state.product.product);
  const profile = useSelector((state) => state.user.profile);
  //  const [, set ] = useState([])

  const [categoryvalue, setCategoryvalue] = useState(product.category);
  const [options, setOptions] = useState([]);
  useEffect(() => {
    async function loadcategories() {
      try {
        const response = await api.get('category');
        const data = response.data.map((bb) => ({
          id: bb.id,
          title: bb.category,
        }));
        setOptions(data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Erro ao carregar categorias'); }
      }
    }
    loadcategories();
  }, [profile.company]);

  async function handleSubmit(data) {
    try {
      await api.put('products3', {
        data,
        id: product.id,
      });
      toast.success('Produto atualizado com sucesso');
      history.push('/products');
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao atualizar Produto'); }
    }
  }

  const [showaddstock, setShowaddstock] = useState(false);

  return (
    <Container6>
      <Form schema={schema} initialData={product} onSubmit={handleSubmit}>
        <Link to="/products"><MdChevronLeft size={30} /></Link>
        <span>Código</span>
        <Input name="bar_code" placeholder="Código do Produto" />
        <span>Descrição</span>
        <Input name="name" placeholder="Nome do Produto" />
        <span>Categoria</span>
        <Select name="category" placeholder="Categoria do Produto" onChange={(e) => setCategoryvalue(e.target.value)} value={categoryvalue} options={options} />
        {/*
              <button type="button" onClick={()=>history.push('/sales')}>
                <MdAddCircleOutline size={30}/>
              </button>
              */}

        <List10>
          <span>Preço</span>
          { profile.level ? null : <span>Custo</span>}
        </List10>
        <List10>
          <Input name="price" placeholder="Preço" width="20px" />
          { profile.level ? <></> : <Input name="average_cost" placeholder="Custo" /> }
        </List10>

        <span>Estoque</span>
        <List11>
          <ul>
            { profile.level ? <></> : <Check name="stockpile" /> }
          </ul>
          {
                profile.level
                  ? <Input width="20px" name="stock" disabled placeholder="Estoque" />
                  : <Input width="20px" name="stock" placeholder="Estoque" />
              }

          { profile.level ? <></>

            : <RiAddCircleLine size={30} color="#FFF" onClick={() => setShowaddstock(!showaddstock)} />}
        </List11>
        { !showaddstock ? null
          : (
            <>
              <span>Entrada no Estoque</span>
              <List10>
                <Input name="addstock" placeholder="Quantidade" width="20px" />
                <Input name="addstockprice" placeholder="Custo médio" width="20px" />
              </List10>
            </>
          )}
        <span>Ativo</span>
        <List11>
          <ul>
            <Check width="10px" name="active" />
          </ul>
          <button type="submit">Atualizar Produto</button>
        </List11>
      </Form>
    </Container6>
  );
}

export default EditProduct;
