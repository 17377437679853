import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';
import Header2 from '~/components/Header2';
import Header3 from '~/components/Header3';
import { Wrapper } from './styles';
import { store } from '~/store';

export default function DefaultLayout({ children }) {
  const { header } = store.getState().user.profile;
  const { username } = store.getState().user.profile;
  return (
    <Wrapper>
      {username !== 'pedro@teste' ? null : <Header2 /> }
      {header === 'below' ? null : <Header /> }
      <Header3 title={children.props.location.pathname} />
      {children}
      {header !== 'below' ? null : <Header /> }
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
};
