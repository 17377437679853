/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import history from '~/services/history';

import AuthLayout from '~/pages/_layouts/auth';
import DefaultLayout from '~/pages/_layouts/default';

import { store } from '~/store';

import { updateTokenRequest } from '~/store/modules/user/actions';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  isLogin,
  ...rest
}) {
  const { signed } = store.getState().auth;
  if (!signed && isPrivate) { history.push('/'); }
  if (signed && isLogin) { history.push('/sales'); }
  const Layout = signed ? DefaultLayout : AuthLayout;

  const dispatch = useDispatch();
  if (store.getState().auth.tokenexpires - new Date().getTime() / 1000 < 3600) {
    if (store.getState().auth.tokenexpires - new Date().getTime() / 1000 > 0) {
      dispatch(updateTokenRequest());
    }
  }

  return (

    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}

RouteWrapper.propTypes = {
  isPrivate: PropTypes.bool,
  isLogin: PropTypes.bool,
  component: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
};
RouteWrapper.defaultProps = {
  isPrivate: false,
  isLogin: false,
};
