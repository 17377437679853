export function paymentSet(data) {
  return {
    type: '@payment/PAYMENT_SET',
    payload: { data },
  };
}
export function paymentSetSuccess(payment) {
  return {
    type: '@payment/PAYMENT_SET_SUCCESS',
    payload: { payment },
  };
}
