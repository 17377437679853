import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import api from '~/services/api';
import history from '~/services/history';
import { Container6 } from '~/pages/_layouts/default/styles';

const schema = Yup.object().shape({
  category: Yup.string().required('A nova categoria é obrigatória'),
});

export default function NewCategory() {
  const profile = useSelector((state) => state.user.profile);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    async function loadcategories() {
      try {
        const response = await api.get('category');
        const data = response.data.map((bb) => ({
          id: bb.id,
          title: bb.category,
        }));
        setOptions(data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar categorias'); }
      }
    }
    loadcategories();
  }, [profile.company]);

  async function handleSubmit(data) {
    try {
      await api.post('category', {
        ...data,
        enterprise: profile.enterprise,
      });
      toast.success('Categoria cadastrada com sucesso!');
      history.push('/newproduct');
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao cadastrar categoria'); }
    }
  }

  return (
    <Container6>

      <Form onSubmit={handleSubmit} schema={schema}>
        <Select name="existscategory" placeholder="Categorias Existentes" options={options} />
        <Input name="category" placeholder="Nova Categoria" />
        <button type="submit">Adicionar Categoria</button>
      </Form>
    </Container6>
  );
}
