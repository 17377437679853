export function saleSet(data) {
  return {
    type: '@sale/SALE_SET',
    payload: { data },
  };
}
export function saleSetSuccess(sale) {
  return {
    type: '@sale/SALE_SET_SUCCESS',
    payload: { sale },
  };
}
