import {
  takeLatest, call, put, all,
} from 'redux-saga/effects';
import { toast } from 'react-toastify';

import history from '~/services/history';
import api from '~/services/api';

import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { username, password } = payload;

    const response = yield call(api.post, 'sessions/', {
      username,
      password,
    });

    const { token, user, tokenexpires } = response.data;
    //    if (user.level !== 0) {
    //      toast.error('Usuário não possúi nível');
    //      return;
    //    }

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, user, tokenexpires));
    history.push('/sales');
  } catch (err) {
    if (err.response) { toast.error(err.response.data.error); } else { toast.error('Falha na autenticação'); }
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { name, username, password } = payload;

    yield call(api.post, 'usersMaster', {
      name,
      username,
      password,
      level: 0,
      avatar_id: 1,
    });
    toast.success('Usuário Cadastrado com sucesso');
    history.push('/');
  } catch (err) {
    if (err.response) { toast.error(err.response.data.error); } else { toast.error('Falha no cadastro'); }
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.auth;
  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_UP_REQUEST', signUp),
  takeLatest('@auth/SIGN_OUT', signOut),

]);
