import produce from 'immer';

const INITIAL_STATE = {
  sale: null,
};

export default function sale(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@sale/SALE_SET': {
        draft.sale = action.payload.sale;
        break;
      }
      case '@sale/SALE_SET_SUCCESS': {
        draft.sale = action.payload.sale;
        break;
      }
      default:
    }
  });
}
