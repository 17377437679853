import React from 'react';
import { Form } from '@unform/web';
import history from '~/services/history';
import { Container } from '~/pages/_layouts/default/styles';

export default function App() {
  async function handleSubmit() {
    history.push('NewEnterprise');
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <strong>- Você receberá R$90,00 em créditos em sua conta.</strong>
        <strong>- A cada mês serão debitados R$30,00.</strong>
        <strong>
          - Para manter o acesso após estes 3 meses de utilização,
          será necessário a transferência de créditos.
        </strong>
        <strong>
          - Nos reservamos o direito de reajustes mediante a aviso prévio.
        </strong>
        <strong>
          - Caso esteja de acordo, seja bem vindo!
        </strong>
      </Form>
      <button onClick={handleSubmit} type="button">Continuar</button>
    </Container>
  );
}
