import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`

 background: linear-gradient(-20deg, #7159c1, #333);
 min-height: calc(100vh);
 max-width: 100%;
 padding: 0 7px 0 7px;
 header {
  max-width: 800px;
  display: flex;
  align-items: center;
  align-self: center;
  button {
    border: 0;
    background: none;
  }
  strong {
    color: #fff;
    font-size: 12px;
    margin: 0 5px;
  }
  ul {
    display: flex;
    justfy-content:space-between;
  }
}
`;
export const Container = styled.div`
  max-width: 800px;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding-bottom:110px;

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 6px;
    height: 30px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  a {
    font-size: 10px;
    padding-left: 10px;
    font-weight: bold;
    color: #FFF;
  }
  ul {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 7px;
    margin-top: 5px;
  }
  ul2 {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 10px;
    font-size: 10px;
    span {
      font-size: 10px;
    }
    strong {
      font-size: 10px;
    }

  }
  ul3 {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-gap: 10px;
    font-size: 10px;
    span {
      font-size: 10px;
    }
    strong {
      font-size: 10px;
    }
    button {
      border: 0;
      background: none;
    }
  }


  select {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 6px;
    height: 40px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
    option {
      background: rgba(0, 0, 0, 0.8);
    }
  }

form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    boder-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  li {
  select {
    background: rgba(0, 0, 0, 0.1);
    height:40px;
    border: 0;
      border-radius: 6px;
      margin: 0 10px 10px;
    padding: 0 15px;
    color: #fff;
    padding: 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
    option {
      background: rgba(0, 0, 0, 0.8);
    }
  }
}

  span {
    color: #F64C75;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
  }

  hr {
    border: 0;
    height: 1px;
    background: rgba(255,255,255,0.2);
    margin: 10px 0 20px;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: #3b9eff;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 12px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#3b9eff')}
    }
  }
}

> button {
  width: 100%;
  margin: 15px 0 0;
  height: 44px;
  background: #f64375;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 12px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.08, '#f64375')}
  }
}
`;
export const Container2 = styled.div`

padding-bottom:100px;
margin: auto;
ul2 {
  input {
    width: 70px;
  }
  strong {
    color: #fff;
    font-size: 14px;
    margin: 0 0px;
  }
}
hr {
  border: 0;
  height: 3px;
  margin: 5px 0 5px;
}
  max-width: 800px;
  margin: 0px auto;

  display: flex;
  flex-direction: column;

  header {
    display: flex;
    align-items: center;
    align-self: center;

    button {
      border: 0;
      background: none;
    }

    strong {
      color: #fff;
      font-size: 12px;
      margin: 0 0px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(1,1fr);
    grid-gap: 7px;
    margin-top: 5px;
  }

  select {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 6px;
    height: 30px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
    option {
      background: rgba(0, 0, 0, 0.8);
    }
  }
  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 6px;
    height: 30px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  input2 {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    border-radius: 6px;
    height: 40px;
    width: 40px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }



  button {
    margin: 5px 5px 0;
    height: 30px;
    background: #3b9eff;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 12px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.08, '#3b9eff')}
    }
  }


`;
export const Container6 = styled.div`
display: flex;

max-width: 800px;
padding-bottom:100px;
flex-direction: column;
margin:auto;
header {
  background: linear-gradient(-5deg, #7159c1, #333);
  width: 100%;
  display: flex;
  padding: 5px;
  flex-direction: column;
  strong {
    font-size: 20px;
  }
}

form {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  a {
    color:#fff;
    align-self:center;
    align-items:center;
    margin: 0px;
  }

  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    height: 40px;
    padding: 0 15px;
    color: #fff;
    border-radius: 6px;
    margin: 0 10px 10px;
    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  select {
    background: rgba(0, 0, 0, 0.1);
    height:40px;
    border: 0;
    border-radius: 6px;
    margin: 0 10px 10px;
    padding: 0 15px;
    color: #fff;
    padding: 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
    option {
      background: rgba(0, 0, 0, 0.8);
    }
  }

  span {
    color: #fff;
    margin: 0 0 0 10px;
    font-size: 10px;
  }

  hr {
    border: 0;
    height: 1px;
    background: rgba(255,255,255,0.2);
    margin: 10px 0 20px;
  }

  button {
    margin: 10px ;
    height: 40px;
    background: #3b9eff;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 12px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#3b9eff')}
    }

  }


}
> button {

  margin: 10px ;
  height: 40px;
  background: #f64375;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 12px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.08, '#f64375')}
  }
`;
export const Grid = styled.div`
display: grid;
grid-gap: 5px;
aligin-self:center;
aligin-items:center;
grid-template-columns: repeat(${(props) => (props.columns)},1fr);
padding: 0;
width:100%;

`;
export const List = styled.div`
button {
  width: 100%;
  margin: 15px 0 0;
  height: 60px;
  background: #f64375;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 12px;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.08, '#f64375')}
  }
`;

export const List2 = styled.li`

max-width: 800px;
padding: 5px;
border-radius: 4px;
background: #FFF;

justify-content:space-between;

margin-top: 5px;
grid-gap: 7px;

strong {
  display: block;
  color: ${(props) => (props.noactive ? '#999' : '#7159c1')};
  font-size: 12px;
  font-weight: normal;

}
button {
  background: none;
  color: #999
}
span {
  display: block;
  margin-top: 1px;
  color: ${(props) => (props.noactive ? '#999' : '#666')};

}
strong {
  color: #999;
  font-size: 12px;
  margin: 0 10px;
  align-items: center;
  align-self: center;

}
ul {
  justify-content:space-between;
  display: flex;
  flex-direction:line
  grid-template-columns: repeat(1,1fr);
  grid-gap: 7px;
  margin-top: 1px;
  align-items: center;
  align-self: center;
  aside {
    width: 90px;
    display: flex;
    align-items: center;
  }
  span {
  }
  input {
    margin: 0;
  }
  ul2 {
    input {
      align:center;
      align-items:center;
      align-self:center;
      width:180px;
      color:black;
      a {
        color:black;

      }
    }
  }

}
img {
  height: 20px;
  width: 20px;
}

`;
export const List3 = styled.li`
max-width: 800px;
padding: 5px;
border-radius: 4px;
background: #FFF;
display:flex;
justify-content:space-between;
align-items: center;
cursor:pointer;

opacity: ${(props) => (props.noactive ? 0.6 : 1)};
strong {
  display: block;
  color: ${(props) => (props.noactive ? '#999' : '#7159c1')};
  font-size: 12px;
  font-weight: normal;
}
span {
  display: block;
  margin-top: 3px;
  font-size:10px;
  color: ${(props) => (props.noactive ? '#999' : '#666')};
}
aside {
  display: flex;
  align-items: center;
}
aside2 {
  display: flex;
  flex-direction:column;
  min-width: 70px;
  align-items: center;
}
aside3 {
  display: flex;
  flex-direction:column;
  min-width: 60px;
  align-items: center;
  ul {
    display: flex;
    flex-direction:line;

  }
}


`;
export const List5 = styled.li`
max-width: 800px;
padding: 0px;
border-radius: 4px;
display:flex;
justify-content:space-between;
align-self: center;
align-items: center;
margin: 0;

opacity: ${(props) => (props.noactive ? 0.6 : 1)};
strong {
  display: block;
  color: #fff;
  font-size: 14px;
  align: center;
  font-weight: normal;
}
button {
  background: none;
  border: 0;
}


`;
export const List6 = styled.li`
padding: 5px;
border-radius: 4px;
background: #FFF;
display:grid;
grid-template-columns: 0.12fr 0.18fr 0.18fr 0.18fr 0.18fr 0.18fr;
grid-gap: 5px;

strong {
  color: ${(props) => (props.noactive ? '#999' : '#7159c1')};
  font-size: 12px;
  font-weight: normal;
}
span {
  font-size:10px;
  color: ${(props) => (props.noactive ? '#999' : '#666')};
}
`;
export const List63 = styled.li`
max-width: 800px;
padding: 5px;
border-radius: 4px;
background: #FFF;
display:grid;
grid-template-columns: 0.5fr 0.5fr;
grid-gap: 5px;
justify-content:space-between;
align-items: center;

opacity: ${(props) => (props.noactive ? 0.6 : 1)};
strong {
  display: block;
  color: ${(props) => (props.noactive ? '#999' : '#7159c1')};
  font-size: 12px;
  align-items: center;
  font-weight: normal;
}
span {
  display: block;
  margin-top: 3px;
  font-size:10px;
  color: ${(props) => (props.noactive ? '#999' : '#666')};
}
aside {
  display: flex;
  align-items: center;
}
aside2 {
  display: flex;
  flex-direction:column;
  min-width: 70px;
  align-items: center;
}
aside3 {
  display: flex;
  flex-direction:column;
  min-width: 60px;
  align-items: center;
  ul {
    display: flex;
    flex-direction:line;

  }
}


`;
export const List7 = styled.li`
max-width: 800px;
padding: 5px;
border-radius: 4px;
background: #FFF;
display:grid;
grid-template-columns: 1fr 1fr 20px;
grid-gap: 5px;
justify-content:space-between;
align-items: center;

opacity: ${(props) => (props.noactive ? 0.6 : 1)};
strong {
  display: block;
  color: ${(props) => (props.noactive ? '#999' : '#7159c1')};
  font-size: 12px;
  align-items: center;
  font-weight: normal;
}
span {
  display: block;
  margin-top: 3px;
  font-size:10px;
  color: ${(props) => (props.noactive ? '#999' : '#666')};
}
aside {
  display: flex;
  align-items: center;
}
aside2 {
  display: flex;
  flex-direction:column;
  min-width: 70px;
  align-items: center;
}
aside3 {
  display: flex;
  flex-direction:column;
  min-width: 60px;
  align-items: center;
  ul {
    display: flex;
    flex-direction:line;

  }
}


`;
export const List10 = styled.li`
display: grid;
grid-gap: 5px;
grid-template-columns: repeat(2,1fr);
padding: 0 20px 0 0;
width:100%;
input {
  width: 100%;
}
select {
  width: 100%;
}
`;
export const List11 = styled.li`
display: grid;
grid-gap: 5px;
aligin-self:center;
aligin-items:center;
grid-template-columns: 40px 1fr 40px;
padding: 0;
width:100%;
input {
  width: 95%;
  padding: 200px;
  margin-right: 20px;
}
ul {
  input {
    width: 20px;
  }
}
`;

export const List13 = styled.li`
display: grid;
grid-gap: 5px;
aligin-self:center;
aligin-items:center;
grid-template-columns: 1fr 80px;
padding: 0;
width:100%;
input {
  width: 90%;
  padding: 10px;
  margin-right: 10px;
}
`;
export const List14 = styled.li`
max-width: 800px;
padding: 5px;
border-radius: 4px;
background: #FFF;
display:flex;
justify-content:space-between;
align-items: center;
cursor:pointer;

ul {
  display: flex;
  flex-direction: column;
}

opacity: ${(props) => (props.noactive ? 0.6 : 1)};
strong {
  display: block;
  color: ${(props) => (props.noactive ? '#999' : '#7159c1')};
  font-size: 12px;
  font-weight: normal;
}
span {
  display: block;
  margin-top: 3px;
  font-size:10px;
  color: ${(props) => (props.noactive ? '#999' : '#666')};
}
aside {
  display: flex;
  align-items: center;
  min-width: 90px;
}
`;
export const List15 = styled.li`
display: grid;
grid-gap: 5px;
grid-template-columns: repeat(3,1fr);
padding: 0 20px 5px 0;
width:100%;
opacity: ${(props) => (props.noactive ? 0.6 : 1)};
input {
  width: 100%;
}
select {
  width: 100%;
}
button {
  height: 30px;
  background: blue;
  border: none;
  opacity: ${(props) => (props.noactive ? 0.6 : 1)};
}

`;
export const List16 = styled.li`
max-width: 800px;
padding: 5px;
border-radius: 4px;
background: #FFF;
display:flex;
justify-content:space-between;
align-items: center;
cursor:pointer;

ul {
  display: flex;
  flex-direction: column;
}

opacity: ${(props) => (props.noactive ? 0.6 : 1)};
strong {
  display: block;
  color: ${(props) => (props.noactive ? '#999' : '#7159c1')};
  font-size: 12px;
  font-weight: normal;
}
span {
  display: block;
  margin-top: 3px;
  font-size:10px;
  color: ${(props) => (props.noactive ? '#999' : '#666')};
}
aside {
  display: flex;
  align-items: center;
  min-width: 90px;
}
`;
export const List17 = styled.li`
display: grid;
grid-gap: 5px;
grid-template-columns: repeat(3,1fr);
padding: 0 20px 0 0;
width:100%;
input {
  width: 100%;
}
select {
  width: 100%;
}
`;
export const List18 = styled.li`
display: grid;
grid-gap: 5px;
aligin-self:center;
aligin-items:center;
grid-template-columns: 70px 1fr 40px;
padding: 0;
width:100%;
input {
  width: 90%;
  padding: 10px;
  margin-right: 10px;
}
`;
export const List20 = styled.div`

display: grid;
grid-template-columns: repeat(1,1fr);
grid-gap: 7px;
margin-top: 5px;
color:white;
`;
export const List21 = styled.div`

display: flex;
flex-direction: line;
align-self: center;
align-items: center;

color: #fff;
button {
  margin: 5px 0 0;
  height: 44px;
  background: none;
  font-weight: bold;
  color: #fff;
  border: 0;
  border-radius: 4px;
  font-size: 12px;
  transition: background 0.2s;
}
`;
export const List22 = styled.div`

display: grid;
grid-template-columns: repeat(1,1fr);
grid-gap: 3px;
margin-top: 0px;

span {
  font-size: 14px;
}

input {
  font-size: 10px;
  font-weight: normal;
    color:#808080;
  &::placeholder {
    color:#808080;
  }
}
`;
export const List23 = styled.li`
display: grid;
grid-gap: 5px;
grid-template-columns: repeat(4,1fr);
padding: 0 20px 5px 0;
width:100%;
opacity: ${(props) => (props.noactive ? 0.6 : 1)};
input {
  width: 100%;
}
select {
  width: 100%;
}
button {
  height: 30px;
  background: blue;
  border: none;
  opacity: ${(props) => (props.noactive ? 0.6 : 1)};
}

`;
export const List24 = styled.li`
display: grid;
grid-gap: 5px;
grid-template-columns: repeat(2,1fr);
padding: 0 20px 5px 0;
width:100%;
opacity: ${(props) => (props.noactive ? 0.6 : 1)};
input {
  width: 100%;
}
select {
  width: 100%;
}
button {
  height: 30px;
  background: blue;
  border: none;
  opacity: ${(props) => (props.noactive ? 0.6 : 1)};
}

`;
export const List25 = styled.li`
display: grid;
grid-gap: 5px;
aligin-self:center;
aligin-items:center;
grid-template-columns: 1fr 40px;
padding: 0;
width:100%;
input {
  width: 90%;
  padding: 10px;
  margin-right: 10px;
}
`;
