/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import {
  FaCashRegister, FaChartLine, FaListUl, FaWrench, FaShoppingCart, FaDropbox,
} from 'react-icons/fa';
import { MdPayment, MdPerson } from 'react-icons/md';
import { BsList } from 'react-icons/bs';
import { FcSalesPerformance } from 'react-icons/fc';
import { updateProfileRequest } from '~/store/modules/user/actions';
import api from '~/services/api';
import {
  Container, Content, Content2, Profile,
} from './styles';

// import { FiLogOut } from 'react-icons/fi'
import history from '~/services/history';

// import logo from '~/assets/Lello.png'
// import logo2 from '~/assets/Tiradentes.png'
// import logo3 from '~/assets/empresa1.png'
// import logo4 from '~/assets/empresa2.png'

export default function Header() {
  const profile = useSelector((state) => state.user.profile);
  const dispatch = useDispatch();
  const [logotipo, setLogotipo] = useState();
  const [showReports] = useState(true);

  useEffect(() => {
    async function loadlogo() {
      const logo2 = await api.get('enterpriseavatar');
      setLogotipo(logo2.data.avatar.url);
    }
    loadlogo(profile);
  }, [profile]);

  const ITEM_HEIGHT = 100;

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    history.push(e);
    setAnchorEl(null);
  };

  async function handlechangecompany() {
    if (!profile.level) {
      const response = await api.get('enterprise');
      const data2 = response.data.map((aa, _id) => ({
        id: _id + 1,
        atual: profile.company === aa.company ? 'x' : '',
        company: aa.company,
      }));
      const atual = data2.find((x) => x.atual === 'x').id;
      const nova = atual === data2.length ? 1 : atual + 1;
      const { company } = data2.find((x) => x.id === nova);

      if (company !== profile.company) {
        const data = {};
        data.id = profile.id;
        data.username = profile.username;
        data.cam_id = profile.cam_id;
        data.company = company;
        dispatch(updateProfileRequest(data));
        const a = history.location.pathname;
        history.push('/');
        history.push(a);
      }
    }
  }

  return (
    <Container position={profile.header}>

      {!showReports ? null
        : (
          <Content2 position={profile.header} charts={profile.level}>
            <nav>
              <Link to="/newproduct">Novo Produto  </Link>
              <Link to="/payments">
                <MdPayment style={{ color: 'green' }} />
                Pagamentos
                {' '}
              </Link>
              <Link to="/cash">
                <FaCashRegister />
                Caixa
                {' '}
              </Link>
              {profile.level ? null : (
                <Link to="/charts">
                  <FaChartLine />
                  Gráficos
                  {' '}
                </Link>
              )}

            </nav>
          </Content2>
        )}

      <Content position={profile.header}>
        <img src={logotipo} onClick={() => handlechangecompany()} alt="Vendas" />
        <nav>
          <Link to="/newsale">Nova Venda </Link>
          <Link to="/sales">
            <FcSalesPerformance />
            Vendas
            {' '}
          </Link>
          <Link to="/products">
            <FaShoppingCart />
            Produtos
            {' '}
          </Link>

        </nav>
        <aside>
          <Profile>
            <div>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleClick}
              >

                <FaListUl size="20" color={profile.header === 'below' ? '#fff' : null} />
              </IconButton>

              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    background: '#7159c1',
                    color: '#fff',
                    maxHeight: ITEM_HEIGHT * 4.5,
                    width: '20ch',
                  },
                }}
              >

                <MenuItem color="primary" onClick={() => handleClose('/profile')}>
                  <MdPerson />
                  {' '}
                  Perfil
                </MenuItem>

                {profile.enterprise !== 'lello' ? null
                  : (
                    <MenuItem onClick={() => handleClose('/encalhe')}>
                      <BsList />
                      {' '}
                      Encalhe
                    </MenuItem>
                  )}
                <MenuItem onClick={() => handleClose('/inventario')}>
                  <BsList />
                  {' '}
                  Inventário
                </MenuItem>
                <MenuItem color="white" onClick={() => handleClose('/entrada')}>
                  <BsList color="white" />
                  {' '}
                  Entrada
                </MenuItem>

                {profile.level ? null : (
                  <MenuItem onClick={() => handleClose('/stock')}>
                    <FaDropbox />
                    {' '}
                    Estoque
                  </MenuItem>
                ) }
                {profile.level ? null : (
                  <MenuItem onClick={() => handleClose('/config')}>
                    <FaWrench />
                    {' '}
                    Configurações
                  </MenuItem>
                ) }
              </Menu>
            </div>

            {/*
            <img src={
              profile.avatar.url ? profile.avatar.url :
              'https://api.adorable.io/avatars/50/abott@adorable.png'
            }
            alt="Avatar" />
            */}
          </Profile>
        </aside>
      </Content>
    </Container>
  );
}
