import { all } from 'redux-saga/effects';
import auth from './auth/sagas';
import user from './user/sagas';
import product from './product/sagas';
import sale from './sale/sagas';
import payment from './payment/sagas';
import basket from './basket/sagas';

export default function* rootSaga() {
  return yield all([auth, user, product, sale, payment, basket]);
}
