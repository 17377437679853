import styled from 'styled-components';
import { darken } from 'polished';

export const Wrapper = styled.div`
  height: 100%;
  //background: #333;
  background: linear-gradient(-90deg, #7159c1, #333);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  `;

export const Content = styled.div`
  width: 100%;
  max-width: 250px;
  text-align: center;

  form {
    display: flex;
    flex-direction: column;
    margin-top: 30px;


  input {
    background: rgba(0, 0, 0, 0.1);
    border: 0;
    boder-radius: 4px;
    height: 44px;
    padding: 0 15px;
    color: #fff;
    margin: 0 0 10px;

    &::placeholder {
      color: rgba(255, 255, 255, 0.7);
    }
  }

  span {
    color: #F64C75;
    align-self: flex-start;
    margin: 0 0 10px;
    font-weight: bold;
  }

  button {
    margin: 5px 0 0;
    height: 44px;
    background: none;
    font-weight: bold;
    color: #fff;
    border: 0;
    border-radius: 4px;
    font-size: 16px;
    transition: background 0.2s;

    &:hover {
      background: ${darken(0.03, '#3b9eff')}
    }
  }

a {
  color: #FFF;
  margin-top: 15px;
  font-size: 16px;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

}
  `;

export const List26 = styled.div`
  display: grid;
  grid-gap: 5px;
  grid-template-columns: repeat(1,1fr) 30px;
  padding: 0 20px 5px 0;
  width:100%;
  opacity: ${(props) => (props.noactive ? 0.6 : 1)};
  align-items: top;
  input {
    width: 100%;
    border-radius: 10px;

  }

  button {
    padding: 10px;
    color: red;

  }



`;

export const List27 = styled.div`
display: grid;
grid-gap: 5px;
grid-template-columns: repeat(1,1fr) 30px;
padding: 0 20px 5px 0;
width:100%;
img {
  horizontal-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}


`;
