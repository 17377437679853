import React, { useState, useEffect, useMemo } from 'react';
import * as Yup from 'yup';
import {
  format, parseISO, subMonths, addMonths, isAfter, startOfMonth, endOfMonth,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import { Input, Form } from '@rocketseat/unform';
import { useSelector } from 'react-redux';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';
import {
  List6, List20, Container,
} from '~/pages/_layouts/default/styles';
import { formatPrice } from '~/util/format';

const schema = Yup.object().shape({
  newcash: Yup.number()
    .required('O valor é obrigatório')
    .transform((_, value) => +value.replace(/,/, '.'))
    .positive('O valor deve ser positivo'),
  qty: Yup.string().required('A quantidade é obrigatória'),
});

function Cash() {
  const [totalpayments, setTotalpayments] = useState(0);
  const [totalsales, setTotalsales] = useState(0);
  const [totalcash, setTotalcash] = useState(0);
  const [lastcash, setLastcash] = useState(0);
  const [newcash, setNewcash] = useState();
  const [totaldebit, setTotaldebit] = useState(0);
  const [totalcredit, setTotalcredit] = useState(0);
  const [totalpix, setTotalpix] = useState(0);
  const profile = useSelector((state) => state.user.profile);
  const [schedule, setSchedule] = useState();
  const [month, setMonth] = useState(new Date());
  const [newdate, setNewdate] = useState(new Date());
  const [changeDate, setChangeDate] = useState(false);

  const dateFormatted = useMemo(() => format(newdate, "YYY-MM-dd'T'HH:mm:ss", { locale: pt }),
    [newdate]);

  async function loadCash() {
    const historyCash = await api.get('cash', { params: { date1: startOfMonth(month), date2: endOfMonth(month) } });
    setSchedule(historyCash.data);

    const cash = await api.get('lastcash');

    let price = 0;
    let date = new Date();

    if (cash.data.length !== 0) {
      price = cash.data[0].price;
      date = cash.data[0].date;
    }

    setLastcash(price);
    const date1 = date;
    const date2 = new Date();
    const response = await api.get('sales', { params: { date1, date2 } });
    const response2 = await api.get('payments', { params: { date1, date2 } });

    const debit = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto === 'Débito')
      .reduce((result, teste) => result + teste.pgtoValue, 0);

    const pix = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto === 'Pix')
      .reduce((result, teste) => result + teste.pgtoValue, 0);

    const credit = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto === 'Crédito')
      .reduce((result, teste) => result + teste.pgtoValue, 0);

    const money = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto === 'Dinheiro')
      .reduce((result, teste) => result + teste.pgtoValue, 0);

    const debit2 = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto2 === 'Débito')
      .reduce((result, teste) => result + teste.pgto2Value, 0);

    const pix2 = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto2 === 'Pix')
      .reduce((result, teste) => result + teste.pgto2Value, 0);

    const credit2 = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto2 === 'Crédito')
      .reduce((result, teste) => result + teste.pgto2Value, 0);

    const money2 = response.data
      .filter((item) => item.active === true)
      .filter((x) => x.pgto2 === 'Dinheiro')
      .reduce((result, teste) => result + teste.pgto2Value, 0);

    const payments = response2.data
      .filter((item) => item.active === true)
      .reduce((result, teste) => result + teste.price, 0);

    setTotaldebit(debit + debit2);
    setTotalcredit(credit + credit2);
    setTotalpix(pix + pix2);
    setTotalsales(money + money2);
    setTotalpayments(payments);
    setTotalcash(money + money2 + price - payments);
  }

  async function handleCloseCash() {
    try {
      await api.post('cash', {
        price: newcash,
        debit: totaldebit,
        credit: totalcredit,
        pix: totalpix,
        money: totalsales,
        payment: totalpayments,
        diff: newcash - totalcash,
        company: profile.company,
        enterprise: profile.enterprise,
        date: format(changeDate ? newdate : new Date(), "YYY-MM-dd'T'HH:mm:ss'-03:00'", { locale: pt }),
      });

      setTotalcash(newcash);
      setLastcash(newcash);
      setTotalsales(0);
      setNewcash(0);
      setNewdate(new Date());
      toast.success('Caixa fechado com sucesso!');
      loadCash();
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao fechar caixa'); }
    }
  }

  useEffect(() => {
    loadCash();
    // eslint-disable-next-line
  },[totalcash,month])

  const [showright, setShowright] = useState(false);

  function handlePrevMonth() {
    setMonth(subMonths(month, 1));
    if (isAfter(new Date(), subMonths(month, 2))) {
      setShowright(true);
    } else {
      setShowright(false);
    }
  }
  function handleNextMonth() {
    setMonth(addMonths(month, 1));
    if (isAfter(new Date(), addMonths(month, 2))) {
      setShowright(true);
    } else {
      setShowright(false);
    }
  }

  function handleDate(data) {
    setNewdate(parseISO(data));
    setChangeDate(true);
  }

  return (

    <Container>

      <Form schema={schema} onSubmit={handleCloseCash}>
        <List20>
          { !profile.level
            ? (
              <>
                <Input name="newcash" onChange={(e) => setNewcash(e.target.value.replace(/,/, '.'))} value={newcash} placeholder="Dinheiro em Caixa" />
                <Input name="date" value={dateFormatted} onChange={(e) => handleDate(e.target.value)} type="datetime-local" placeholder="Data do Fechamento" />
                { newcash ? <button type="submit" onClick={handleCloseCash}>Fechar Caixa </button> : null}
              </>
            )
            : (
              <span>
                Último Fechamento:
                {' '}
                {formatPrice(lastcash)}
              </span>
            ) }
        </List20>
      </Form>

      <List20>
        <List6 key="Data">
          <strong>Data</strong>
          <strong>Cartão</strong>
          <strong>Pix</strong>
          <strong>Dinheiro</strong>
          <strong>Saída</strong>
          <strong>
            Caixa
            <br />
            Diff
          </strong>
        </List6>
        <List6 key="Atual">
          <span />
          <span>{formatPrice(totaldebit + totalcredit)}</span>
          <span>{formatPrice(totalpix)}</span>
          <span>{formatPrice(totalsales)}</span>
          <span>
            -
            {formatPrice(totalpayments)}
          </span>
          <span>{formatPrice(totalcash)}</span>
        </List6>
      </List20>
      <header>
        <button type="button" onClick={handlePrevMonth}>
          <MdChevronLeft size={30} color="#ffff" />
        </button>
        <strong>{format(month, 'MMMM/YYY', { locale: pt })}</strong>
        { !showright ? null
          : (
            <button type="button" onClick={handleNextMonth}>
              <MdChevronRight size={30} color="#ffff" />
            </button>
          )}
      </header>

      <List20>

        { !schedule ? null

          : schedule.map((aa) => (
            <List6 key={aa.id}>
              <span>{format(parseISO(aa.date), "dd'/'MM' 'HH:mm", { locale: pt })}</span>
              <span>{formatPrice(aa.debit + aa.credit)}</span>
              <span>{formatPrice(aa.pix)}</span>
              <span>{formatPrice(aa.money)}</span>
              <span>
                -
                {formatPrice(aa.payment)}
              </span>
              <span>
                {formatPrice(aa.price)}
                <br />
                {formatPrice(aa.diff)}

              </span>
            </List6>
          ))}
      </List20>

    </Container>
  );
}

export default Cash;
