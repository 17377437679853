import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { MdChevronLeft } from 'react-icons/md';
import {
  Form, Input, Check, Select,
} from '@rocketseat/unform';

import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '~/services/api';
import history from '~/services/history';
import { Container6, List11 } from '~/pages/_layouts/default/styles';

function EditPayment() {
  const payment = useSelector((state) => state.payment.payment);
  const [newdate, setNewdate] = useState(payment.date);
  const dateFormatted = useMemo(() => format(parseISO(newdate), "YYY-MM-dd'T'HH:mm", { locale: pt }),
    [newdate]);
  const optionstype = [
    { id: 'Fornecedor', title: 'Fornecedor' },
    { id: 'Funcionário', title: 'Funcionário' },
    { id: 'Diversos', title: 'Diversos' },
  ];

  // eslint-disable-next-line consistent-return
  async function handleSubmit(data) {
    try {
      const cash = await api.get('lastcash');
      const last_cash = format(parseISO(cash.data[0].date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt });
      const data_pagamento = format(parseISO(payment.date), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt });
      if (last_cash > data_pagamento) {
        return toast.error('Caixa fechado');
      }
      if (last_cash > newdate) {
        return toast.error('Caixa fechado');
      }

      data.date = format(parseISO(newdate), "YYY-MM-dd'T'HH:mm':00-03:00'", { locale: pt });

      await api.put('payments', {
        data,
        id: payment.id,
      });

      toast.success('Pagamento atualizado com sucesso');
      history.push('/payments');
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao atualizar o Pagamento'); }
    }
  }

  return (
    <Container6>

      <Form initialData={payment} onSubmit={handleSubmit}>
        <Link to="/payments"><MdChevronLeft size={30} /></Link>

        <span>Usuário</span>
        <Input name="seller" disabled placeholder="Usuário" />
        <span>Valor</span>
        <Input name="price" placeholder="Quantidade" />
        <span>Categoria</span>
        <Select name="category" placeholder="Forma de Pagamento" options={optionstype} />
        <span>Descrição</span>
        <Input name="description" placeholder="Descrição" />

        <span>Data</span>
        <Input name="date" value={dateFormatted} onChange={(e) => setNewdate(e.target.value)} type="datetime-local" placeholder="Data do Pagamento" />

        <span>Ativo</span>
        <List11>
          <ul>
            <Check width="5px" name="active" />
          </ul>
          <button type="submit">Atualizar Pagamento</button>
        </List11>
      </Form>
    </Container6>
  );
}

export default EditPayment;
