import React, { useState, useEffect } from 'react';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { formatPrice } from '~/util/format';
import history from '~/services/history';

import api from '~/services/api';
import {
  Grid, List, List63, List20, Container,
} from '~/pages/_layouts/default/styles';

function Config() {
  const [schedule, setSchedule] = useState([]);
  const [funds, setFunds] = useState('');
  const profile = useSelector((state) => state.user.profile);

  useEffect(() => {
    async function loadDebts() {
      try {
        const response = await api.get('debt', { params: { active: true } });
        setSchedule(response.data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }
    async function loadFunds() {
      try {
        const response = await api.get('enterprise', { params: { active: true } });
        setFunds(response.data[0]);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }

    loadDebts();
    loadFunds();
  }, [profile.company]);

  return (

    <Container>

      { profile.level ? <></>
        : (
          <>
            <strong>
              Saldo restantes em conta:
              {' '}
              {formatPrice(funds.balance)}
            </strong>
            <strong>
              Valor Atual do plano:
              {' '}
              {formatPrice(funds.plan)}
              /mês
            </strong>
            <Grid columns="2">
              <List><button type="button" onClick={() => history.push('/deposit')}>Faça um depósito</button></List>
              <List><button type="button" onClick={() => history.push('/register')}>Criar Vendedor</button></List>

              <List><button type="button" onClick={() => history.push('/users')}>Lista de Usuários</button></List>
            </Grid>

            { schedule.length === 0 ? null : (
              <>
                <strong>Lançamentos:</strong>
                <List20>
                  <List63 key="Data">
                    <strong>Período</strong>
                    <strong>Valor</strong>
                  </List63>
                </List20>
                <header />
              </>
            )}

            <List20>
              { !schedule ? null
                : schedule.map((aa) => (
                  <List63 key={aa.id}>
                    <span>
                      {format(parseISO(aa.first_period), "dd'/'MM'", { locale: pt })}
                      -
                      {' '}
                      {format(parseISO(aa.end_period), "dd'/'MM'", { locale: pt })}

                    </span>
                    <span>{formatPrice(aa.price)}</span>
                  </List63>
                ))}
            </List20>

          </>
        )}

    </Container>
  );
}

export default Config;
