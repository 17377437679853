import { takeLatest, all, put } from 'redux-saga/effects';

import { productSetSuccess } from './actions';

export function* loadProduct({ payload }) {
  yield put(productSetSuccess(payload.data));
}

export default all([
  takeLatest('@product/PRODUCT_SET', loadProduct),
]);
