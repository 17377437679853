import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSVLink } from 'react-csv';
import { toast } from 'react-toastify';
import history from '~/services/history';
// import AvatarInput from './AvatarInput';
// import { Container } from './styles';
import api from '~/services/api';
// import { toast } from 'react-toastify';
import { Container6 } from '~/pages/_layouts/default/styles';

export default function Downloads() {
  const profile = useSelector((state) => state.user.profile);
  const [products, setProducts] = useState([]);
  const [sales, setSales] = useState([]);
  const [payments, setPayments] = useState([]);

  useEffect(() => {
    async function loadProducts() {
      try {
        const response = await api.get('products', { params: { itens_por_pagina: 1000000 } });

        /*
        var convertida = response.data.products.map(function(obj) {
          return Object.keys(obj).map(function(chave) {
              return obj[chave];
          });
      });
*/

        const convertida = response.data.products.map((item) => [
          item.id, item.last_sale, item.bar_code, item.name, item.last_cost,
          item.average_cost, item.description, item.category, item.price,
          item.active, item.stock, item.stockpile, item.company,
        ]);
        const newStatuses = [
          ['id', 'last_sale', 'bar_code', 'name', 'last_cost', 'average_cost',
            'description', 'category', 'price', 'active', 'stock', 'stockpile', 'company']]
          .concat(convertida);
        setProducts(newStatuses);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar produtos'); }
      }
    }

    async function loadSales() {
      try {
        const date1 = '01/01/2000';
        const date2 = new Date();
        const response = await api.get('sales', { params: { date1, date2 } });
        const convertida = response.data.map((item) => [
          item.id, item.discount, item.seller, item.date, item.active, item.note, item.bar_code,
          item.name, item.description, item.pgto, item.category, item.price, item.average_cost,
          item.qty, item.total_price, item.created_at, item.company,
        ]);
        const newStatuses = [
          ['id', 'discount', 'seller', 'date', 'active', 'note', 'bar_code',
            'name', 'description', 'pgto', 'category', 'price', 'average_cost',
            'qty', 'total_price', 'created_at', 'company']]
          .concat(convertida);
        setSales(newStatuses);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }
    async function loadPayments() {
      try {
        const date1 = '01/01/2000';
        const date2 = new Date();
        const response = await api.get('payments', { params: { date1, date2 } });
        const convertida = response.data.map((item) => [
          item.id, item.active, item.description, item.category, item.price,
          item.created_at, item.company, item.enterprise, item.date, item.seller,
        ]);

        const newStatuses = [
          ['id', 'active', 'description', 'category', 'price',
            'created_at', 'company', 'enterprise', 'date', 'seller']]
          .concat(convertida);
        setPayments(newStatuses);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }

    loadProducts();
    loadSales();
    loadPayments();
  }, [profile.company]);

  return (
    <Container6>

      { profile.level ? <></>
        : (
          <>
            { !products ? <></> : <CSVLink data={products}>{`Produtos ${products.length}`}</CSVLink> }
            { !sales ? <></> : <CSVLink data={sales}>{`Vendas ${sales.length}`}</CSVLink> }
            { !payments ? <></> : <CSVLink data={payments}>{`Pagamentos ${payments.length}`}</CSVLink> }
          </>
        ) }

    </Container6>
  );
}
