// import React, {useState, useEffect} from "react";
import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import * as Yup from 'yup';
// import { List2 } from './styles';
import {
  MdDelete, MdExpandLess, MdExpandMore, MdChevronLeft, MdChevronRight,
} from 'react-icons/md';
import { toast } from 'react-toastify';
// import { RiQrCodeLine } from 'react-icons/ri'
import { FaBarcode } from 'react-icons/fa';
import { Input, Form, Select } from '@rocketseat/unform';
import { useSelector } from 'react-redux';
import {
  Grid, Container2, List2, List3, List5, List18, List22,
} from '~/pages/_layouts/default/styles';
import { formatPrice } from '~/util/format';
import api from '~/services/api';
// import { Link } from 'react-router-dom';
// import QrReader from 'react-qr-reader'
import Scanner from '~/components/BarCode/Scanner';
import Result from '~/components/BarCode/Result';
import history from '~/services/history';

const schema = Yup.object().shape({
  bar_code: Yup.string().required('O código é obrigatório'),
  qty: Yup.number().required('A quantidade é obrigatória'),
});

function NewSale() {
  const [sale, setSale] = useState([]);
  const [basket, setBasket] = useState([]);
  const [bar_code, setBar_code] = useState('');
  const [qty, setQty] = useState(1);
  const [indice, setIndice] = useState(0);
  const [total_compra, setTotal_compra] = useState(0);
  const [pgto, setPgto] = useState();
  const [pgto2, setPgto2] = useState();
  const [pgtoValue, setPgtoValue] = useState(0);
  const [pgto2Value, setPgto2Value] = useState(0);
  const profile = useSelector((state) => state.user.profile);
  const [troco, setTroco] = useState(0);
  const [page, setPage] = useState(1);
  const [totalpages, setTotalpages] = useState(1);
  const [loading, Setloading] = useState(false);
  const [BarCodeScanning, setBarCodeScanning] = useState(false);
  const [results, setResults] = useState([]);
  const scannerRef = useRef(null);
  const [getcamid, setGetcamid] = useState();
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const [options2, setOptions2] = useState([]);
  const options = useMemo(() => [
    { id: 'Dinheiro', title: 'Dinheiro' },
    { id: 'Débito', title: 'Débito' },
    { id: 'Crédito', title: 'Crédito' },
    { id: 'Pix', title: 'Pix' },
    { id: 'Inventário', title: 'Inventário' },
    { id: 'Entrada', title: 'Entrada' },
    { id: 'Encalhe', title: 'Encalhe' },
  ], []);

  async function loadproducts(codigo) {
    try {
      const itens_por_pagina = 10;
      if (codigo) {
        if (codigo[0].length > 1) {
          // eslint-disable-next-line prefer-destructuring
          codigo = codigo[0];
        }
      }
      // setBar_code(codigo)
      // loadProduct(results[0])
      setBarCodeScanning(false);
      setShowBarcodeScanner(false);
      setResults([]);

      if (codigo.length >= 2) {
        const response = await api.get('products3', { params: { bar_code: codigo, itens_por_pagina, page } });
        const data = response.data.products.map((bb) => ({
          ...bb,
          priceFormatted: formatPrice(bb.price),
          total_price: qty * bb.price,
          indice,
          totalFormatted: formatPrice(qty * bb.price),
          qty,
          discount: 0,
        }));
        setSale(data);
        // setBar_code(codigo)
        setTotalpages(Math.ceil(response.data.total_products / itens_por_pagina));

        if (data.length === 1 && data[0].bar_code === codigo) {
          if (data[0].active) {
            setBasket([
              data[0],
              ...basket,
            ]);
            // setTotal_compra(total_compra+data[0].total_price)
            setSale([]);
            setBar_code('');
            setIndice(indice + 1);
            setQty(1);
          }
        }
      } else {
        setSale([]);
        setTotalpages(1);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao carregar vendas'); }
    }
  }
  async function handleFinalize(e) {
    Setloading(true);
    let newPgtoValue;
    let newPgto2Value;

    newPgtoValue = pgtoValue;
    newPgto2Value = pgto2Value;

    if (pgtoValue > 0) {
      if (troco < 0) {
        toast.error('Troco não pode ser menor que zero');
        return;
      }
      if (troco > 0) {
        if (pgto !== 'Dinheiro' && pgto2 !== 'Dinheiro') {
          toast.error('Troco não pode menor que o Dinheiro recebido1');
          return;
        }

        if (pgto === 'Dinheiro') {
          if (pgtoValue < troco) {
            toast.error('Troco não pode menor que o Dinheiro recebido2');
            return;
          }
          newPgtoValue = pgtoValue - troco;
        }

        if (pgto2 === 'Dinheiro') {
          if (pgto2Value < troco) {
            toast.error('Troco não pode menor que o Dinheiro recebido3');
            return;
          }
          newPgto2Value = pgto2Value - troco;
        }
      }
      if (!pgto2) newPgtoValue = e.total_price;
    }
    const {
      // eslint-disable-next-line no-shadow
      bar_code, name, discount, description, category, price, qty, total_price, id, note, stockpile,
    } = e;

    await api.post('sales', {
      bar_code,
      seller: profile.username,
      name,
      discount,
      description,
      category,
      active: true,
      price,
      qty,
      total_price: Number.parseFloat(total_price).toFixed(2),
      pgto,
      pgto2,
      pgtoValue: newPgtoValue === 0 ? total_price : newPgtoValue,
      pgto2Value: newPgto2Value,
      note,
      id,
      company: profile.company,
      enterprise: profile.enterprise,
    });
    setBasket([]);
    setTotal_compra(0);
    setPgto();
    toast.success('Venda realizada com sucesso!');
    if (stockpile) {
      await api.put('products4', {
        ...e,
        pgto,
        subtract_stock: qty,
      });
    }
    Setloading(false);
    history.push('/sales');
  }
  function handleAdd(e) {
    // delete basket.id
    if (e.active) {
      setPgto(null);
      setPgtoValue(0);
      setPgto2(null);
      setPgto2Value(0);
      setTroco(0);
      setBasket([
        e,
        ...basket,
      ]);
      // setTotal_compra(total_compra+e.total_price)
      setSale([]);
      setBar_code('');
      setIndice(indice + 1);
      setQty(1);
      setPage(1);
      setTotalpages(1);
    }
  }

  /*
  const [showQrcodeScanner, setShowQrcodeScanner] = useState(false)
  function handleQrCodeScan(data) { if (data) {
    setBar_code(data)
    setShowQrcodeScanner(false)}}
  function handleQrCodeError(err) { console.error(err) }
*/

  useEffect(() => {
    async function results_barcode() {
      if (results[0]) {
        setBar_code(results[0]);
        loadproducts(results[0]);
        setBarCodeScanning(false);
        setShowBarcodeScanner(false);
        setResults([]);
      }
    }
    results_barcode();
    // eslint-disable-next-line
  }, [results])

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner);
    setBarCodeScanning(!BarCodeScanning);
  }

  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile.cam_id) {
          setGetcamid(mediaDevice.deviceId);
        }
      });
    }
    loadCam_id();
  }, [profile.cam_id]);

  /*
  const [optionscam, setOptionscam ] = useState([])
  const [selectedcam, setSelectedcam] =useState()
  useEffect(() => {
    async function gotDevices() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices()
      const opções = []
      mediaDevices.forEach(mediaDevice => {
        if (mediaDevice.kind === 'videoinput') {
          opções.push({id: mediaDevice.deviceId , title: mediaDevice.label  })
        }
      })
      setOptionscam(opções)
    }
    gotDevices()
  }, [])
*/

  function handlenote(j, k) {
    setBasket(basket.map((y) => ({
      ...y,
      note: y.indice === j ? k : y.note,
    })));
  }

  function handlediscount(j, k) {
    if (!k) { k = 0; }
    setBasket(basket.map((y) => ({
      ...y,
      discount: y.indice === j ? Number.parseFloat(k) : y.discount,
      priceFormatted: y.indice === j ? formatPrice(y.price - k) : formatPrice(y.price),
      total_price: y.indice === j ? y.qty * y.price - k : y.total_price,
      totalFormatted: y.indice === j
        ? formatPrice(y.qty * (y.price - k)) : formatPrice(y.total_price),
    })));
  }

  function handleDelete(k) {
    setBasket(
      basket.filter((y) => y.indice !== k),
    );
  // setTotal_compra(total_compra-j)
  }

  function handleaddqty(j) {
    // setTotal_compra(total_compra + (basket[k].price-basket[k].discount))
    setBasket(basket.map((y) => ({
      ...y,
      qty: y.indice === j ? y.qty + 1 : y.qty,
      total_price: y.indice === j ? (y.qty + 1) * y.price - y.discount : y.total_price,
      totalFormatted: y.indice === j
        ? formatPrice((y.qty + 1) * y.price - y.discount) : formatPrice(y.total_price),
    })));
  }
  function handleremoveqty(j) {
  // if (basket[k].qty > 1) { setTotal_compra(total_compra - basket[k].price + basket[k].discount) }
    setBasket(basket.map((y) => ({
      ...y,
      qty: y.indice === j && y.qty > 1 ? y.qty - 1 : y.qty,
      total_price: y.indice === j && y.qty > 1
        ? (y.qty - 1) * y.price - y.discount : y.total_price,
      totalFormatted: y.indice === j && y.qty > 1
        ? formatPrice((y.qty - 1) * y.price - y.discount) : formatPrice(y.total_price),

    })));
  }

  function handlesearch(e) {
    setPage(1);
    setBar_code(e);
    loadproducts(e);
  }

  function handleaddpage() {
    setPage(page + 1);
  }
  function handlereturnpage() {
    setPage(page - 1);
  }

  useEffect(() => {
    async function pages() {
      loadproducts(bar_code);
    }
    pages();
  // eslint-disable-next-line
}, [page])

  useEffect(() => {
    setTroco(Number.parseFloat(pgtoValue)
      + Number.parseFloat(pgto2Value)
      - Number.parseFloat(total_compra));
  },
  [pgtoValue, pgto2Value, total_compra]);

  useEffect(() => {
    if (pgto === 'Dinheiro' || pgto === 'Crédito' || pgto === 'Débito' || pgto === 'Pix') {
      setOptions2(options.filter(({ id }) => id !== pgto && id !== 'Encalhe' && id !== 'Entrada' && id !== 'Inventário'));
    }
  }, [options, pgto]);

  useEffect(() => {
    async function Total_compra() {
      setTotal_compra(basket.reduce((result, teste) => result + teste.total_price, 0));
    }
    Total_compra();
  }, [basket]);

  return (
    <Container2>
      <header>
        <strong>{formatPrice(total_compra)}</strong>
      </header>

      <Form schema={schema}>
        <List18>
          <Input name="qty" type="number" step="0.01" size="1" onChange={(e) => setQty(e.target.value)} value={qty} placeholder="Qtd" />
          <Input size="18" autoComplete="off" value={bar_code} onChange={(e) => handlesearch(e.target.value)} name="bar_code" placeholder="Pesquisar Produto" />
          {/* <MdSearch onClick={() => loadProduct(bar_code)} size={30} color='#FFF' /> */}
          <FaBarcode cursor="pointer" size={30} onClick={() => handleBarcode()} color="#FFF" />
        </List18>

        { !showBarcodeScanner ? <></>
          : (
            <div>
              <ul className="results">
                {results.map((result) => (result.codeResult
                && <Result key={result.codeResult.code} result={result} />))}
              </ul>
              <div ref={scannerRef} style={{ position: 'relative' }}>
                <canvas className="drawingBuffer" style={{ position: 'absolute', top: '0px', border: '3px solid green' }} width="480" height="350" />
                {BarCodeScanning ? (
                  <Scanner
                    aaaa={getcamid}
                    scannerRef={scannerRef}
                    teste={results}
                    onDetected={(result) => setResults([...results, result])}
                  />
                ) : null}
              </div>
            </div>
          )}

        { !sale[0] ? null
          : (
            <List5>
              {page === 1 ? null : (
                <button type="button" onClick={() => handlereturnpage()}>
                  <MdChevronLeft size={30} color="#fff" />
                </button>
              )}
              <strong>
                Página
                {' '}
                {page}
                /
                {totalpages}
              </strong>
              {page >= totalpages ? null : (
                <button type="button" onClick={() => handleaddpage()}>
                  <MdChevronRight size={30} color="#fff" />
                </button>
              )}
            </List5>
          )}

        {/* { sale[0] ? <button type="submit">Adicionar</button> : <></>} */}
        <ul>
          { sale.map((aa, _id) => (
            // eslint-disable-next-line no-underscore-dangle
            <List3 onClick={() => handleAdd(sale[_id])} key={String(aa.id)} noactive={!aa.active}>
              <strong name="name">
                {aa.bar_code}
                {' '}
                -
                {' '}
                {aa.name}
              </strong>
              <strong name="price">
                {aa.stockpile ? aa.stock : ''}
                {' '}
                {aa.stockpile ? '-' : ''}
                {' '}
                {aa.priceFormatted}
                {' '}
              </strong>
              {/* <img alt='Imagem' src={aa.File.url}></img> */}
            </List3>
          ))}
        </ul>
      </Form>
      <ul>

        <Form onSubmit={() => basket.map((jj) => (handleFinalize(jj)))}>

          <Grid columns="3">
            { sale[0] ? null
              : (
                <>
                  {basket[0]
                    ? (
                      <Select
                        name="pgto"
                        placeholder="Pagamento"
                        onChange={(e) => {
                          setPgto(e.target.value);
                          setPgto2(undefined);
                        }}
                        options={options}
                      />
                    )
                    : <></>}

                  {pgto === 'Dinheiro' || pgto === 'Crédito' || pgto === 'Débito' || pgto === 'Pix' ? (
                    <>
                      <ul2>
                        <Input
                          type="number"
                          step="0.01"
                          onChange={(e) => {
                            setPgtoValue(e.target.value !== '' ? e.target.value : 0);
                          }}
                          name="pgtoValue"
                        />
                      </ul2>
                      {
                        pgtoValue > 0 && !basket[1] && (
                        <ul2>
                          {' '}
                          <strong placeholder="Valor" name="troco">Troco: R$ </strong>
                          <strong placeholder="Valor" name="trocovalor">
                            {troco.toFixed(2)}
                            {' '}
                          </strong>
                        </ul2>
                        )
                      }

                    </>
                  ) : <></>}
                </>
              )}

          </Grid>
          <Grid columns="3">
            { (sale[0] || !basket[0] || basket[1] || pgtoValue <= 0) ? <></>
              : ((pgto === 'Dinheiro' || pgto === 'Crédito' || pgto === 'Débito' || pgto === 'Pix') && (
                <>
                  <Select name="pgto2" placeholder="Pagamento" onChange={(e) => setPgto2(e.target.value)} options={options2} />

                  <ul2>
                    <Input
                      type="number"
                      step="0.01"
                      onChange={(e) => {
                        setPgto2Value(e.target.value !== '' ? e.target.value : 0);
                      }}
                      name="pgto2Value"
                    />
                  </ul2>

                </>
              )
              )}
          </Grid>

          <Grid columns="2">

            { !pgto ? null
              : (
                <>
                  { !loading
                    ? <button type="submit">Finalizar Venda</button>
                    : <button type="submit">Carregando...</button>}

                  <button type="button" onClick={() => window.print()}>Imprimir</button>
                </>
              )}
          </Grid>

          { sale[0] ? null : basket.map((jj, _id) => (
            <List2 key={String(jj.indice)} active>

              <ul>
                <List22>
                  <span name="name2">
                    {jj.bar_code}
                    -
                    {jj.name}
                  </span>
                  <Input name="note" placeholder="Observação" onChange={(e) => handlenote(jj.indice, e.target.value)} />
                  <Input name="discount" type="number" step="0.01" placeholder="Desconto" onChange={(e) => handlediscount(jj.indice, e.target.value, jj.total_price)} />
                </List22>
                <ul2>
                  <MdExpandLess onClick={() => handleaddqty(jj.indice, _id)} size="20" />
                  <span>{jj.qty}</span>
                  <MdExpandMore onClick={() => handleremoveqty(jj.indice, _id)} size="20" />
                </ul2>
                <aside>
                  {/* <img alt='Imagem' height='20px' src={jj.File.url}></img> */}
                  <strong>
                    {jj.priceFormatted}
                    {' '}
                    {jj.totalFormatted}
                  </strong>
                  <button onClick={() => handleDelete(jj.indice, jj.total_price)} type="button">
                    <MdDelete size="20" />
                  </button>
                </aside>
              </ul>
            </List2>

          ))}
        </Form>
      </ul>

    </Container2>
  );
}

export default NewSale;
