import React, { useState, useMemo, useEffect } from 'react';
import {
  format, subDays, addDays, startOfDay, endOfDay, parseISO, isAfter,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import { useSelector } from 'react-redux';

import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { toast } from 'react-toastify';
import history from '~/services/history';

import api from '~/services/api';
import { Container, List3 } from '~/pages/_layouts/default/styles';

function Sales() {
  const [schedule, setSchedule] = useState([]);
  const [date, setDate] = useState(new Date());
  const profile = useSelector((state) => state.user.profile);

  const [show_date, setShow_date] = useState(true);
  const [showright, setShowright] = useState(false);

  const dateFormatted = useMemo(
    () => format(date, "d 'de' MMMM", { locale: pt }),
    [date],
  );

  useEffect(() => {
    async function loadSales() {
      try {
        setShow_date(true);
        const date1 = startOfDay(date);
        const date2 = endOfDay(date);
        const response = await api.get('encalhe', {
          params: {
            date1, date2, active: true, ordercreate: 'DESC',
          },
        });
        setSchedule(response.data);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }
    loadSales();
  }, [date, dateFormatted, profile.company]);

  function handlePrevDay() {
    setDate(subDays(date, 1));
    if (isAfter(new Date(), subDays(date, 2))) { setShowright(true); } else { setShowright(false); }
  }
  function handleNextDay() {
    setDate(addDays(date, 1));
    if (isAfter(new Date(), addDays(date, 2))) { setShowright(true); } else { setShowright(false); }
  }

  const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

  return (

    <Container>
      <header>
        {!show_date ? null
          : (
            <>
              <button type="button" onClick={handlePrevDay}>
                <MdChevronLeft size={30} color="#ffff" />
              </button>
              <strong>
                {dateFormatted}
                {' '}
                (
                {days[date.getDay()]}
                )
              </strong>
              { !showright ? null
                : (
                  <button type="button" onClick={handleNextDay}>
                    <MdChevronRight size={30} color="#ffff" />
                  </button>
                )}
            </>
          )}

      </header>

      <></>

      <ul>
        { schedule.map((aa) => (
          <List3 key={String(aa.id)} noactive={!aa.active}>

            <span>
              {format(parseISO(aa.date), "dd'/'MM'-'HH:mm", { locale: pt })}
              {' '}
              {aa.name}
              {' '}
              {aa.pgto}
              {' '}
              {aa.note}
            </span>

            <aside3>
              <ul>
                <span>{aa.qty}</span>
              </ul>
            </aside3>

          </List3>
        ))}
      </ul>
      <ul />

    </Container>
  );
}

export default Sales;
