import { takeLatest, all, put } from 'redux-saga/effects';
import history from '~/services/history';

import { saleSetSuccess } from './actions';

export function* loadSale({ payload }) {
  yield put(saleSetSuccess(payload.data));
  history.push('/editsale');
}

export default all([
  takeLatest('@sale/SALE_SET', loadSale),
]);
