import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import SignOut from '../pages/SignOut';

import Profile from '../pages/Profile';
import Products from '../pages/Products';
import Sales from '../pages/Sales';
import Payments from '../pages/Payments';
import Cash from '../pages/Cash';
import NewSale from '../pages/NewSale';
import NewProduct from '../pages/NewProduct';
import NewCategory from '../pages/NewCategory';
import Config from '../pages/Config';
import Users from '../pages/Users';
import EditProduct from '../pages/EditProduct';
import EditSale from '../pages/EditSale';
import EditPayment from '../pages/EditPayment';
import Charts from '../pages/Charts';
import NewEnterprise from '../pages/NewEnterprise';
import Encalhe from '../pages/Encalhe';
import Upload from '../pages/Upload';
import Inventario from '../pages/Inventario';
import Entrada from '../pages/Entrada';
import Stock from '../pages/Stock';
import Downloads from '../pages/Downloads';
import Deposit from '../pages/Deposit';
import Teste from '../pages/Teste';
import Conditions from '../pages/Conditions';

export default function Routes() {
  return (
    <Switch>
      <Route path="/conditions" component={Conditions} />
      <Route path="/Teste" component={Teste} />
      <Route path="/" exact component={SignIn} isLogin />
      <Route path="/deposit" component={Deposit} />
      <Route path="/cash" component={Cash} isPrivate />
      <Route path="/charts" component={Charts} isPrivate />
      <Route path="/config" component={Config} isPrivate />
      <Route path="/editpayment" component={EditPayment} isPrivate />
      <Route path="/editproduct" component={EditProduct} isPrivate />
      <Route path="/editsale" component={EditSale} isPrivate />
      <Route path="/encalhe" component={Encalhe} isPrivate />
      <Route path="/entrada" component={Entrada} isPrivate />
      <Route path="/inventario" component={Inventario} isPrivate />
      <Route path="/newcategory" component={NewCategory} isPrivate />
      <Route path="/newenterprise" component={NewEnterprise} isPrivate />
      <Route path="/newproduct" component={NewProduct} isPrivate />
      <Route path="/newsale" component={NewSale} isPrivate />
      <Route path="/payments" component={Payments} isPrivate />
      <Route path="/products" component={Products} isPrivate />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/sales" component={Sales} isPrivate />
      <Route path="/register" component={SignUp} isPrivate />
      <Route path="/signout" component={SignOut} />
      <Route path="/stock" component={Stock} isPrivate />
      <Route path="/upload" component={Upload} isPrivate />
      <Route path="/users" component={Users} isPrivate />
      <Route path="/downloads" component={Downloads} isPrivate />

      <Route path="/" component={() => <h1>404</h1>} />
    </Switch>
  );
}
