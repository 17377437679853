/* eslint-disable no-underscore-dangle */
import React, {
  useState, useMemo, useEffect, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  format, subDays, addDays, startOfDay, endOfDay, parseISO, isAfter,
} from 'date-fns';
import pt from 'date-fns/locale/pt';
import { Input } from '@rocketseat/unform';
import { FaBarcode } from 'react-icons/fa';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { toast } from 'react-toastify';
import { formatPrice } from '~/util/format';
import { saleSet } from '~/store/modules/sale/actions';
import history from '~/services/history';
import Scanner from '~/components/BarCode/Scanner';
import Result from '~/components/BarCode/Result';

import api from '~/services/api';
import { Container, List3 } from '~/pages/_layouts/default/styles';

function Sales() {
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState([]);
  const [date, setDate] = useState(new Date());
  const profile = useSelector((state) => state.user.profile);
  const [bar_code, setBar_code] = useState();
  const [total_sales, setTotal_sales] = useState(0);
  const [show_date, setShow_date] = useState(true);
  const days = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];
  const [BarCodeScanning, setBarCodeScanning] = useState(false);
  const [results, setResults] = useState([]);
  const scannerRef = useRef(null);
  const [showright, setShowright] = useState(false);
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);
  const dateFormatted = useMemo(
    () => format(date, "d 'de' MMMM", { locale: pt }),
    [date],
  );

  useEffect(() => {
    async function loadSales() {
      try {
        if (!bar_code) {
          setShow_date(true);
          const date1 = startOfDay(date);
          const date2 = endOfDay(date);
          const response = await api.get('sales', {
            params: {
              date1, date2, ordercreate: 'DESC',
            },
          });
          setSchedule(response.data);
          setTotal_sales(response.data
            .filter((sale) => sale.active)
            .reduce((x, y) => x + y.total_price, 0));
        } else if (bar_code.length >= 2) {
          setShow_date(false);
          const response = await api.get('sales3', { params: { bar_code } });
          setSchedule(response.data);
          setTotal_sales(response.data
            .filter((sale) => sale.active)
            .reduce((x, y) => x + y.total_price, 0));
        }
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar vendas'); }
      }
    }
    loadSales();
  }, [date, dateFormatted, profile.company, bar_code]);

  function handlePrevDay() {
    setBar_code('');
    setDate(subDays(date, 1));
    if (isAfter(new Date(), subDays(date, 2))) { setShowright(true); } else { setShowright(false); }
  }
  function handleNextDay() {
    setBar_code('');
    setDate(addDays(date, 1));
    if (isAfter(new Date(), addDays(date, 2))) { setShowright(true); } else { setShowright(false); }
  }

  async function handleEdit(data) {
    try {
      const response = await api.get('products5', { params: { bar_code: data.bar_code } });
      setSchedule([]);
      dispatch(saleSet({
        ...data,
        price: data.price.toLocaleString('pt-PT'),
        total_price: data.total_price.toLocaleString('pt-PT'),
        average_cost: data.average_cost.toLocaleString('pt-PT'),
        atual_stock: response.data.products.length > 0 ? response.data.products[0].stock : ' ',
      }));
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao carregar venda'); }
    }
  }

  useEffect(() => {
    async function loadProduct() {
      if (results[0]) {
        setBar_code(results[0]);
        setBarCodeScanning(false);
        setShowBarcodeScanner(false);

        setResults([]);
      }
    }
    loadProduct();
  }, [results]);

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner);
    setBarCodeScanning(!BarCodeScanning);
  }

  const [getcamid, setGetcamid] = useState();
  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile.cam_id) {
          setGetcamid(mediaDevice.deviceId);
        }
      });
    }
    loadCam_id();
  }, [profile.cam_id]);

  function vendaTest(aa, pgtoNr) {
    return (
      <List3
        onClick={() => handleEdit(aa)}
        key={String(aa.id)}
        noactive={!aa.active}
      >

        <ul>
          <span>
            {' '}
            {aa.name}
          </span>
          <span>
            {format(parseISO(aa.date), "dd'/'MM'/'yy'-'HH:mm", { locale: pt })}
            {' '}
            {aa.pgto}
            {aa.pgto2 && ` / ${aa.pgto2}`}
            {' '}
            {aa.note}
          </span>
        </ul>
        <aside3>
          <ul>
            <span>
              {aa.qty}
              {' '}
              x
              {' '}
            </span>
            <span>{formatPrice(aa.price)}</span>
          </ul>
          <strong>{formatPrice(pgtoNr === 1 ? aa.pgtoValue : aa.pgto2Value)}</strong>
        </aside3>

      </List3>
    );
  }

  return (

    <Container>
      <header>

        {!show_date ? null
          : (
            <>
              <button type="button" onClick={handlePrevDay}>
                <MdChevronLeft size={30} color="#ffff" />
              </button>
              <strong>
                {dateFormatted}
                {' '}
                (
                {days[date.getDay()]}
                )
              </strong>
              { !showright ? null
                : (
                  <button type="button" onClick={handleNextDay}>
                    <MdChevronRight size={30} color="#ffff" />
                  </button>
                )}
            </>
          )}
        {profile.level ? null : <strong>{formatPrice(total_sales)}</strong>}

      </header>

      {/* <List4 onClick={() => history.push('/newsale')}><strong>Nova Venda</strong></List4> */}
      <ul3>
        <Input autoComplete="off" placeholder="Pesquisar" name="bar_code" value={bar_code} onChange={(e) => setBar_code(e.target.value)} />
        <button type="button" onClick={() => handleBarcode()}>
          <FaBarcode size={30} color="#FFF" />

        </button>
      </ul3>

      { !showBarcodeScanner ? <></>
        : (
          <div>
            <ul className="results">
              {results.map((result) => (result.codeResult
              && <Result key={result.codeResult.code} result={result} />))}
            </ul>
            <div ref={scannerRef} style={{ position: 'relative' }}>
              <canvas className="drawingBuffer" style={{ position: 'absolute', top: '0px', border: '3px solid green' }} width="480" height="350" />
              {BarCodeScanning ? (
                <Scanner
                  aaaa={getcamid}
                  scannerRef={scannerRef}
                  teste={results}
                  onDetected={(result) => setResults([...results, result])}
                />
              ) : null}
            </div>
          </div>
        )}
      <></>
      <ul>
        <h3 style={{ color: '#fff' }}>
          {`${schedule.filter((x) => x.pgto === 'Dinheiro' && x.active).reduce((total) => total + 1, 0)
          + schedule.filter((x) => x.pgto2 === 'Dinheiro' && x.active).reduce((total) => total + 1, 0)} Venda(s) em Dinheiro: ${(schedule.filter((x) => x.pgto === 'Dinheiro' && x.active).reduce((total, sale) => total + sale.pgtoValue, 0)
          + schedule.filter((x) => x.pgto2 === 'Dinheiro' && x.active).reduce((total, sale) => total + sale.pgto2Value, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        </h3>
        { schedule.filter((x) => x.pgto === 'Dinheiro').map((aa) => vendaTest(aa, 1))}
        { schedule.filter((x) => x.pgto2 === 'Dinheiro').map((aa) => vendaTest(aa, 2))}
        <br />
        <h3 style={{ color: '#fff' }}>
          {`${schedule.filter((x) => x.pgto === 'Débito' && x.active).reduce((total) => total + 1, 0)
           + schedule.filter((x) => x.pgto2 === 'Débito' && x.active).reduce((total) => total + 1, 0)} Venda(s) em Débito: ${(schedule.filter((x) => x.pgto === 'Débito' && x.active).reduce((total, sale) => total + sale.pgtoValue, 0)
           + schedule.filter((x) => x.pgto2 === 'Débito' && x.active).reduce((total, sale) => total + sale.pgto2Value, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        </h3>
        { schedule.filter((x) => x.pgto === 'Débito').map((aa) => vendaTest(aa, 1))}
        { schedule.filter((x) => x.pgto2 === 'Débito').map((aa) => vendaTest(aa, 2))}
        <br />
        <h3 style={{ color: '#fff' }}>
          {`${schedule.filter((x) => x.pgto === 'Crédito' && x.active).reduce((total) => total + 1, 0)
          + schedule.filter((x) => x.pgto2 === 'Crédito' && x.active).reduce((total) => total + 1, 0)} Venda(s) em Crédito: ${(schedule.filter((x) => x.pgto === 'Crédito' && x.active).reduce((total, sale) => total + sale.pgtoValue, 0)
          + schedule.filter((x) => x.pgto2 === 'Crédito' && x.active).reduce((total, sale) => total + sale.pgto2Value, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        </h3>
        { schedule.filter((x) => x.pgto === 'Crédito').map((aa) => vendaTest(aa, 1))}
        { schedule.filter((x) => x.pgto2 === 'Crédito').map((aa) => vendaTest(aa, 2))}
        <br />
        <h3 style={{ color: '#fff' }}>
          {`${schedule.filter((x) => x.pgto === 'Pix' && x.active).reduce((total) => total + 1, 0)
          + schedule.filter((x) => x.pgto2 === 'Pix' && x.active).reduce((total) => total + 1, 0)} Venda(s) em Pix: ${(schedule.filter((x) => x.pgto === 'Pix' && x.active).reduce((total, sale) => total + sale.pgtoValue, 0)
          + schedule.filter((x) => x.pgto2 === 'Pix' && x.active).reduce((total, sale) => total + sale.pgto2Value, 0)).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}`}
        </h3>
        { schedule.filter((x) => x.pgto === 'Pix').map((aa) => vendaTest(aa, 1))}
        { schedule.filter((x) => x.pgto2 === 'Pix').map((aa) => vendaTest(aa, 2))}
      </ul>
      <ul />

    </Container>
  );
}

export default Sales;
