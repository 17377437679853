import produce from 'immer';

const INITIAL_STATE = {
  payment: null,
};

export default function payment(state = INITIAL_STATE, action) {
  return produce(state, (draft) => {
    switch (action.type) {
      case '@payment/PAYMENT_SET': {
        draft.payment = action.payload.payment;
        break;
      }
      case '@payment/PAYMENT_SET_SUCCESS': {
        draft.payment = action.payload.payment;
        break;
      }
      default:
    }
  });
}
