import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// import { Link } from 'react-router-dom';
// import { MdAddCircleOutline} from 'react-icons/md'
import { Form, Input, Select } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import { FaBarcode } from 'react-icons/fa';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { format, parseISO } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { formatPrice } from '~/util/format';
import history from '~/services/history';

import api from '~/services/api';
// import { Container } from './styles';
import {
  Container, List14, List5, Grid, List20, List25,
} from '~/pages/_layouts/default/styles';
import Scanner from '~/components/BarCode/Scanner';
import Result from '~/components/BarCode/Result';

import { productSet } from '~/store/modules/product/actions';

function Products() {
  const dispatch = useDispatch();
  const [schedule, setSchedule] = useState([]);

  if (!useSelector((state) => state.product.product)) {
    dispatch(productSet({
      order: '', page: 1, totalpages: 1, bar_code2: '',
    }));
  }

  const [bar_code, setBar_code] = useState(useSelector((state) => state.product.product).bar_code2);
  const [order, setOrder] = useState(useSelector((state) => state.product.product).order);
  const profile = useSelector((state) => state.user.profile);
  const [page, setPage] = useState(useSelector((state) => state.product.product).page);
  const [totalpages, setTotalpages] = useState(useSelector(
    (state) => state.product.product,
  ).totalpages);
  const [categoryvalue, setCategoryvalue] = useState('Ativos');
  const [showBarcodeScanner, setShowBarcodeScanner] = useState(false);

  function Sort(ascending) {
    // eslint-disable-next-line func-names
    return function (a, b) {
      if (a[order.slice(1)] === b[order.slice(1)]) { return 0; }
      if (a[order.slice(1)] === null) { return 1; }
      if (b[order.slice(1)] === null) { return -1; }
      if (ascending === 'x') { return a[order.slice(1)] < b[order.slice(1)] ? -1 : 1; }
      return a[order.slice(1)] < b[order.slice(1)] ? 1 : -1;
    };
  }

  async function loadproducts(code, cat) {
    try {
      const itens_por_pagina = 20;
      if (code && code.length >= 2) {
        const response = await api.get('products3', {
          params: {
            bar_code: code,
            itens_por_pagina,
            page,
            order,
            order2: order ? order.slice(1) : 'name',
            direction: order.slice(0, 1) === 'y' ? 'DESC' : 'ASC',
            category: cat,
          },
        });
        setCategoryvalue('Filtros');
        setTotalpages(Math.ceil(response.data.total_products / itens_por_pagina));
        setSchedule(response.data.products);
        if (response.data.products.length === 1 && response.data.products[0].bar_code === code) {
          // setSchedule(response.data.products)
          dispatch(productSet({
            ...response.data.products[0],
            order,
            page,
            totalpages,
          }));
          history.push('/editproduct');
          setBar_code('');
        }
      } else {
        const response = await api.get('products', {
          params: {
            itens_por_pagina,
            page,
            order,
            order2: order ? order.slice(1) : 'name',
            direction: order.slice(0, 1) === 'y' ? 'DESC' : 'ASC',
            category: cat,

          },
        });
        setTotalpages(Math.ceil(response.data.total_products / itens_por_pagina));
        setSchedule(response.data.products);
      }
    } catch (err) {
      if (err.response) {
        toast.error(err.response.data.error);
        if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
      } else { toast.error('Falha ao carregar produtos'); }
    }
  }

  const [options2, setOptions2] = useState([]);
  useEffect(() => {
    async function loadcategories() {
      try {
        const response = await api.get('category');
        const data = response.data.map((bb) => ({
          id: bb.id,
          title: bb.category,
        }));
        setOptions2([
          { id: 'Filtros', title: 'Filtros' },
          { id: 'Ativos', title: 'Ativos' },
          { id: 'Inativos', title: 'Inativos' },
          ...data]);
      } catch (err) {
        if (err.response) {
          toast.error(err.response.data.error);
          if (err.response.data.error === 'Token invalid') { history.push('/signout'); }
        } else { toast.error('Falha ao carregar categorias'); }
      }
    }
    loadcategories();
  }, [profile.company]);

  const options = [
    { id: 'xprice', title: 'Menor Preço', teste: true },
    { id: 'yprice', title: 'Maior Preço', teste: false },
    { id: 'ylast_sale', title: 'Vendas Novas', teste: true },
    { id: 'xlast_sale', title: 'Vendas Antigas', teste: true },
    { id: 'ystock', title: 'Maior Estoque', teste: true },
    { id: 'xstock', title: 'Menor Estoque', teste: true },
    { id: 'ycreated_at', title: 'Últimos Criados', teste: true },
  ];

  if (!profile.level) {
    options.push(
      { id: 'yaverage_cost', title: 'Maior Custo', teste: true },
      { id: 'xaverage_cost', title: 'Menor Custo', teste: true },
    );
  }

  const [BarCodeScanning, setBarCodeScanning] = useState(false);
  const [results, setResults] = useState([]);
  const scannerRef = useRef(null);

  useEffect(() => {
    async function loadProduct() {
      if (results[0]) {
        setBar_code(results[0]);
        loadproducts(results[0], categoryvalue);
        setBarCodeScanning(false);
        setShowBarcodeScanner(false);

        setResults([]);
      }
    }
    loadProduct();
    // eslint-disable-next-line
  }, [results])

  async function handleBarcode() {
    await setShowBarcodeScanner(!showBarcodeScanner);
    setBarCodeScanning(!BarCodeScanning);
  }

  const [getcamid, setGetcamid] = useState();
  useEffect(() => {
    async function loadCam_id() {
      const mediaDevices = await navigator.mediaDevices.enumerateDevices();
      mediaDevices.forEach((mediaDevice, _id) => {
        if (_id.toString() === profile.cam_id) {
          setGetcamid(mediaDevice.deviceId);
        }
      });
    }
    loadCam_id();
  }, [profile.cam_id]);

  async function handleEdit(data) {
    setSchedule([]);
    dispatch(productSet({
      ...data,
      order,
      page,
      totalpages,
      bar_code2: bar_code,
      price: data.price.toLocaleString('pt-PT'),
      average_cost: data.average_cost.toLocaleString('pt-PT'),
    }));
    history.push('/editproduct');
  }

  function handlesearch(e) {
    setPage(1);
    setBar_code(e);
    loadproducts(e, categoryvalue);
    dispatch(productSet({
      order, page: 1, totalpages, bar_code2: e,
    }));
  }

  function handleaddpage() {
    setPage(page + 1);
    dispatch(productSet({
      order, page: page + 1, totalpages, bar_code2: bar_code,
    }));
  }
  function handlereturnpage() {
    setPage(page - 1);
    dispatch(productSet({
      order, page: page - 1, totalpages, bar_code2: bar_code,
    }));
  }

  function handleorder(e) {
    setOrder(e);
    setPage(1);
    dispatch(productSet({
      order: e, page: 1, totalpages, bar_code2: bar_code,
    }));
  }

  useEffect(() => {
    async function pages() {
      loadproducts(bar_code, categoryvalue);
    }
    pages();
    // eslint-disable-next-line
    }, [page,order,profile.company])

  function handlecategory(e) {
    setPage(1);
    setBar_code('');
    setCategoryvalue(e);
    loadproducts('', e);
  }

  return (
    <Container>

      <Form>
        <Grid columns="2">
          <Select name="order" placeholder="Ordenar" value={order} onChange={(e) => handleorder(e.target.value)} options={options} />
          <Select name="category" placeholder="Categoria" onChange={(e) => handlecategory(e.target.value)} value={categoryvalue} options={options2} />
        </Grid>
      </Form>

      <Form>
        <List25>
          <Input autoComplete="off" placeholder="Pesquisar" value={bar_code} name="bar_code" onChange={(e) => handlesearch(e.target.value)} />
          <FaBarcode cursor="pointer" onClick={() => handleBarcode()} size={30} color="#FFF" />
        </List25>
      </Form>

      { !showBarcodeScanner ? <></>
        : (
          <div>
            <ul className="results">
              {results.map((result) => (result.codeResult
              && <Result key={result.codeResult.code} result={result} />))}
            </ul>
            <div ref={scannerRef} style={{ position: 'relative' }}>
              <canvas className="drawingBuffer" style={{ position: 'absolute', top: '0px', border: '3px solid green' }} width="480" height="350" />
              {BarCodeScanning ? (
                <Scanner
                  aaaa={getcamid}
                  scannerRef={scannerRef}
                  teste={results}
                  onDetected={(result) => setResults([...results, result])}
                />
              ) : null}
            </div>
          </div>
        )}

      <List5>
        {page === 1 ? null : (
          <button type="button" onClick={() => handlereturnpage()}>
            <MdChevronLeft size={30} color="#fff" />
          </button>
        )}
        <strong>
          Página
          {' '}
          {page}
          /
          {totalpages}
        </strong>
        {page >= totalpages ? null : (
          <button type="button" onClick={() => handleaddpage()}>
            <MdChevronRight size={30} color="#fff" />
          </button>
        )}
      </List5>

      <List20>

        { schedule.sort(Sort(order.slice(0, 1))).map((aa, _id) => (
          <List14
            // eslint-disable-next-line no-underscore-dangle
            onClick={() => handleEdit(schedule[_id])}
            key={String(aa.id)}
            noactive={!aa.active}
          >
            <ul>
              <span>
                {aa.bar_code}
                {' '}
                -
                {' '}
                {aa.category}
              </span>
              <span>{aa.name}</span>
            </ul>
            <aside>
              <ul>
                <span>
                  {aa.stockpile ? aa.stock : ''}
                  {' '}
                  {aa.stockpile ? '-' : ''}
                  {' '}
                  {profile.level ? null : formatPrice(aa.average_cost)}
                  {' '}
                  {formatPrice(aa.price)}
                </span>
                <span>{format(parseISO(aa.last_sale), 'yyy') > 2000 ? format(parseISO(aa.last_sale), "dd'/'MMM'/'yy", { locale: pt }) : null}</span>
              </ul>
            </aside>

          </List14>
        ))}
      </List20>
    </Container>
  );
}

export default Products;
