export function basketSet(data) {
  return {
    type: '@basket/BASKET_SET',
    payload: { data },
  };
}
export function basketSetSuccess(basket) {
  return {
    type: '@basket/BASKET_SET_SUCCESS',
    payload: { basket },
  };
}
